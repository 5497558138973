import { IonHeader } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import '../Shared/theme/style.css';
import { showBackState } from '../Shared/services/ActivityService';
import { employerState } from '../Shared/services/EmployerService';
import isMobile from '../Shared/services/IsMobile';
import { memberState } from '../Shared/services/MemberService';

import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';

interface Props {
  returnTo?: string;
}

export default function HeadSubpage(props: Props) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const history = useHistory();
  const [member, _member] = useRecoilState<any>(memberState);
  const [showBack, _showBack] = useRecoilState<any>(showBackState);
  const [employer, _setEmployer] = useRecoilState<any>(employerState);

  function notTab() {
    const path = history.location.pathname;
    const send =
      path !== '/home' &&
      path !== '/market' &&
      path !== '/benefits' &&
      path !== '/regalos' &&
      path !== '/regalos_v2';
    if (send == true) {
      _showBack(true);
    } else {
      _showBack(false);
    }
    return send;
  }

  useEffect(() => {
    notTab();
  }, []);

  useEffect(() => {
    let startWidth = window.innerWidth;

    function handleResize() {
      let mobile = false;

      if (startWidth >= 900) {
        mobile = false;
      } else {
        mobile = true;
      }

      if (window.innerWidth >= 900 && mobile === true) {
        if (dimensions.width < 900) {
          setDimensions({
            width: window.innerWidth,
          });
          startWidth = window.innerWidth;
        }
      } else if (window.innerWidth < 900 && mobile === false) {
        if (dimensions.width >= 900) {
          setDimensions({
            width: window.innerWidth,
          });
          startWidth = window.innerWidth;
        }
      } else {
        startWidth = window.innerWidth;
      }
    }
    window.addEventListener('resize', handleResize);
  });

  return (
    <>
      {!isMobile() && (
        <>
          <IonHeader>
            <DesktopNavbar member={member} />
          </IonHeader>
          {notTab() && (
            <div
              className='mw-back-button'
              onClick={() => {
                props.returnTo ? history.replace(props.returnTo) : history.goBack();
              }}
            >
              <i className='icon bi-arrow-left-square'></i>
            </div>
          )}
        </>
      )}
      {isMobile() && (
        <>
          <IonHeader>
            <MobileNavbar returnTo={props.returnTo} member={member} logo={employer.headerLogo} />
          </IonHeader>
        </>
      )}
    </>
  );
}
