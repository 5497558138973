import axios from 'axios';
import { atom } from 'recoil';

const APIURL = process.env.REACT_APP_API_HOST;

export const benefitsState = atom({
  key: 'benefitsState', // unique ID (with respect to other atoms/selectors)
  default: [],
});

const benefitService = {
  async getBenefits() {
    return axios.get(`${APIURL}/Benefits/GetBenefits`);
  },
  async getBenefit(id: number) {
    return axios.get(`${APIURL}/Benefits/GetBenefit/${id}`);
  },
  async getBenefitItem(id: number) {
    return axios.get(`${APIURL}/Benefits/GetBenefitItem/${id}`);
  },
  async postReimbursement(form: any) {
    return axios.post(`${APIURL}/Benefits/PostReimbursement`, form);
  },
  async postBenefitDocument(file: any, id: number) {
    return axios.post(`${APIURL}/Benefits/PostBenefitDocument/` + id, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async deleteBenefit(id: number) {
    return axios.delete(`${APIURL}/Benefits/DeleteBenefit/${id}`);
  },
};

export default benefitService;
