import { IonContent, IonPage, useIonViewDidLeave } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import HeadSubpage from '../Nav/HeaderSubpage';
import MemberService, { memberState } from '../Shared/services/MemberService';
import RegalosService, { regalosActivityState } from '../Shared/services/RegalosService';
import '../Shared/theme/style.css';

import RegalosActivity from './RegalosActivity_V2';
import RegalosFavorites from './RegalosFavorites_V2';
import RegalosHero from './RegalosHero_V2';

const Regalos_V2: React.FC = () => {
  const [member, _member] = useRecoilState<any>(memberState);
  const [activities, _activities] = useRecoilState<any>(regalosActivityState);
  const [currentPage, _currentPage] = useState(0);

  useEffect(() => {
    RegalosService.getAllRegalos().then((res) => {
      _activities(res.data);
    });
    MemberService.getMember().then((res) => {
      _member(res.data);
    });
    MemberService.balanceMember().then(() => {
      MemberService.getMember().then((res) => {
        _member(res.data);
      });
    });
  }, []);

  useIonViewDidLeave(() => {
    RegalosService.getAllRegalos().then((res) => {
      _activities(res.data);
    });
  });

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <div className='main-container'>
          <RegalosHero />
          <RegalosFavorites />
          <div className='ion-padding'>
            <RegalosActivity
              activities={activities}
              member={member}
              updated={() => {
                RegalosService.getAllRegalos().then((res) => {
                  _activities(res.data);
                });
              }}
              onIonInfinite={() => {
                const newPage = currentPage + 1;
                _currentPage(newPage);
                RegalosService.getAllRegalos(newPage).then((res) => {
                  const newActivities = activities.concat(res.data);
                  _activities(newActivities);
                  console.log('scroller data', res.data);
                });
              }}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Regalos_V2;
