import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { useRecoilState } from 'recoil';

import '../Shared/theme/style.css';
import PinAuth from '../Auth/PinAuth';
import auth from '../Shared/services/auth';
import memberService, { memberState } from '../Shared/services/MemberService';

interface Props {
  open: boolean;
  onClose: () => void;
  phone: string;
}

export default function ChangePhone(props: Props) {
  const [show, _show] = useState(true);
  const [member, _member] = useRecoilState<any>(memberState);
  const [phone, _phone] = useState<any>(props.phone);

  return (
    <IonModal isOpen={props.open} onDidDismiss={() => props.onClose()}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton
              onClick={() => {
                props.onClose();
              }}
            >
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        {show ? (
          <PinAuth
            authed={() => {
              _show(false);
              _phone(props.phone);
            }}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <div style={{ paddingTop: 40 }}>
              <h2>Edit Phone Number</h2>
              <PhoneInputWithCountrySelect
                placeholder='Enter phone number'
                defaultCountry='US'
                style={{
                  fontSize: 18,
                  backgroundColor: 'white',
                  marginTop: 10,
                }}
                value={phone}
                onChange={(value) => {
                  console.log(value);
                  _phone(value);
                }}
              />
            </div>
            {!member.smsAuthSet && (
              <div
                style={{
                  color: 'red',
                  marginTop: 20,
                  fontSize: 12,
                }}
              >
                Your phone number is NOT set up for SMS login. After hitting save you will be able
                to login to future devices with your phone number.
              </div>
            )}
            <IonButton
              color='dark'
              style={{ marginTop: 20 }}
              onClick={() => {
                const m: any = {};
                console.log('new member', m);
                m.id = member.id;
                auth.setPhone(phone);
                m.phone = phone;
                m.smsAuthSet = true;
                memberService.putMember(m);
                _member(m);
                props.onClose();
              }}
            >
              Save
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonModal>
  );
}
