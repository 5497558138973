import { IonButton, IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { useRecoilState } from 'recoil';

import '../Shared/theme/style.css';
import { useHistory } from 'react-router';
import auth from '../Shared/services/auth';
import memberService, { memberState } from '../Shared/services/MemberService';

export default function SetupPinAuth() {
  const [code, _code] = useState('');
  const [member, _member] = useRecoilState<any>(memberState);
  const [resetPin, _resetPin] = useState(false);
  const [verificationCode, _verificationCode] = useState('');
  const [validEmailCode, _validEmailCode] = useState(false);
  const [showInput, _showInput] = useState(false);
  const [showAuthCode, _showAuthCode] = useState(true);
  const [loading, _loading] = useState(false);

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);
    });
  }, []);

  const history = useHistory();

  function setCode(pin: any) {
    // do something
    auth.setPinFirstTime(pin).then((res) => {
      if (res.data) {
        history.replace('/home');
        _loading(false);
        localStorage.setItem('pin', pin);
        const m = structuredClone(member);
        m.accountPinSet = true;
        _member(m);
      } else {
        // do something
        alert('failed');
      }
    });
  }

  function SetPin() {
    return (
      <div style={{ textAlign: 'center', marginTop: 100, padding: 22 }}>
        <div style={{ fontWeight: 'bold' }}>Set Your PIN</div>
        {member.accountPinSet ? (
          <>
            <div>
              <h2>Oops...</h2>
              <p style={{ maxWidth: 400 }}>Your PIN has already been setup.</p>
              <IonButton
                color='dark'
                size='large'
                onClick={() => {
                  // do something
                  history.goBack();
                }}
              >
                go back
              </IonButton>
            </div>
          </>
        ) : (
          <>
            <p style={{}}>
              Some actions require additional verification. Please set a memorable four digit
              account PIN.
            </p>

            <AuthCode
              inputClassName='mw-code-input'
              onChange={(v) => {
                _code(v);
              }}
              allowedCharacters='numeric'
              autoFocus={true}
              length={4}
            />
            <div style={{ paddingTop: 30 }}>
              <IonButton
                disabled={code.length < 4 || loading}
                color='dark'
                size='small'
                onClick={() => {
                  // do something
                  setCode(code);
                  _loading(true);
                }}
              >
                {loading ? 'Loading...' : 'set account PIN'}
              </IonButton>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <IonPage>
      <IonContent>
        <>{SetPin()}</>
      </IonContent>
    </IonPage>
  );
}
