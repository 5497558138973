import {
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonInput,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  isPlatform,
  IonCheckbox,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import '../theme/style.css';

import { useHistory } from 'react-router';

interface InputProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  showValidation?: boolean;
  type?: any;
  value?: any;
  error?: string;
  autoCapitalize?: string;
  validation: (valid: any) => void;
  onChange: (value: any) => void;
}

interface SearchProps {
  placeholder?: string;
  value?: any;
  onChange: (value: any) => void;
}

interface TextAreaProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  showValidation?: boolean;
  rows?: number;
  value?: any;
  error?: string;
  validation: (valid: any) => void;
  onChange: (value: any) => void;
}

interface CheckboxProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  showValidation?: boolean;
  value?: any;
  error?: string;
  validation: (valid: any) => void;
  onChange: (value: any) => void;
}

interface ItemDollarsProps {
  required?: boolean;
  placeholder?: string;
  showValidation?: boolean;
  max?: number;
  value?: any;
  error?: string;
  validation?: (valid: any) => void;
  onChange: (value: any) => void;
}

interface SelectProps {
  label?: string;
  position?: string;
  required?: boolean;
  placeholder?: string;
  showValidation?: boolean;
  value?: any;
  error?: string;
  multiple?: boolean;
  options: Array<{ id: any; name: string }>;
  validation: (valid: any) => void;
  onChange: (value: any) => void;
}

interface VerifyAddressProps {
  addresses: any;
  show: boolean;
  setShow: (show: boolean) => void;
  address: any;
  onChange: (address: any) => void;
  validation: (valid: any) => void;
  editAddress: () => void;
  placeholder?: string;
  label: string;
  value?: any;
}

const MaxwellForm = {
  Input(props: InputProps) {
    const [dirty, _dirty] = useState(false);
    const [error, _error] = useState(props.error || props.label + ' is required');
    return (
      <div style={{ paddingTop: 0, marginBottom: 18 }}>
        {props.label && props.label !== '' && (
          <IonLabel>
            {props.label}
            {props.required && '*'}
          </IonLabel>
        )}
        <IonInput
          type={props.type ? props.type : 'text'}
          style={{ marginTop: 10 }}
          placeholder={props.placeholder || 'Enter ' + props.label}
          value={props.value}
          onIonChange={(e) => {
            _dirty(true);
            if (props.required && e.target.value === '') {
              props.validation({ valid: false, error });
            } else {
              props.validation({ valid: true, error });
            }
            props.onChange(e.target.value);
          }}
          className='mw-input'
          autoCapitalize={props.autoCapitalize || 'off'}
        />
        {props.showValidation === true && error && props.required && dirty && !props.value && (
          <IonLabel className='error_text' color='danger'>
            {error}
          </IonLabel>
        )}
      </div>
    );
  },
  Select(props: SelectProps) {
    const [dirty, _dirty] = useState(false);
    const [error, _error] = useState(props.error || props.label + ' is required');

    const select = useRef<any>(null);

    useEffect(() => {
      //get the select element width
      const selectElement = select.current;
      if (selectElement) {
        const selectWidth = selectElement.offsetWidth;
        //get the select options width
        const selectOptions = selectElement.querySelector('.select-options');
        if (selectOptions) {
          //set the select options width to the select element width
          selectOptions.style.width = selectWidth + 'px';
        }
      }
    }, [props.value]);

    function handleChange(e: any) {
      _dirty(true);
      if (props.required && e.target.value === '') {
        props.validation({ valid: false, error });
      } else {
        props.validation({ valid: true, error });
      }
      props.onChange(e.target.value);
    }

    return (
      <div style={{ marginTop: 10, marginBottom: 14 }}>
        <IonLabel>
          {props.label}
          {props.required && '*'}
        </IonLabel>
        {!isPlatform('mobile') ? (
          <select
            style={{
              marginTop: 10,
              backgroundColor: '#FCFCFC',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
            className='mw-input'
            multiple={props.multiple || false}
            value={props.value}
            onChange={(e) => handleChange(e)}
          >
            {props.options.map((option: any) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </select>
        ) : (
          <IonSelect
            style={{ marginTop: 10 }}
            interface={'action-sheet'}
            placeholder={props.placeholder || 'Select ' + props.label}
            className='mw-input'
            ref={select}
            multiple={props.multiple || false}
            value={props.value}
            defaultValue={props.value}
            onIonChange={(e) => handleChange(e)}
          >
            {props.options.map((option: any) => {
              return (
                <IonSelectOption key={option.id} value={option.id}>
                  {option.name}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        )}

        {props.showValidation === true && error && props.required && dirty && !props.value && (
          <IonLabel className='error_text' color='danger'>
            {error}
          </IonLabel>
        )}
      </div>
    );
  },
  TextArea(props: TextAreaProps) {
    const [dirty, _dirty] = useState(false);
    const [error, _error] = useState(props.error || props.label + ' is required');
    return (
      <div style={{ paddingTop: 10, marginBottom: 36 }}>
        {props.label && props.label !== '' && (
          <IonLabel>
            {props.label}
            {props.required && '*'}
          </IonLabel>
        )}
        <IonTextarea
          rows={props.rows || 3}
          style={{
            marginTop: 10,
            paddingLeft: 18,
            border: '1px solid black',
            borderRadius: 10,
          }}
          placeholder={props.placeholder || 'Enter ' + props.label}
          value={props.value}
          onIonChange={(e) => {
            _dirty(true);
            if (props.required && e.target.value === '') {
              props.validation({ valid: false, error });
            } else {
              props.validation({ valid: true, error });
            }
            props.onChange(e.target.value);
          }}
        />
        {props.showValidation === true && error && props.required && dirty && !props.value && (
          <IonLabel className='error_text' color='danger'>
            {error}
          </IonLabel>
        )}
      </div>
    );
  },
  DateTime(props: InputProps) {
    const [dirty, _dirty] = useState(false);
    const [error, _error] = useState(props.error || props.label + ' is required');

    const modal = useRef<HTMLIonModalElement>(null);

    return (
      <div style={{ paddingTop: 0, marginBottom: 16 }}>
        {props.label && props.label !== '' && (
          <IonLabel>
            {props.label}
            {props.required && '*'}
          </IonLabel>
        )}
        <div style={{ paddingTop: 8 }}></div>
        <IonDatetimeButton
          datetime='datetime'
          className='mw-width-99'
          color='dark'
          style={{ width: '100%', marginTop: 10 }}
        ></IonDatetimeButton>
        <IonModal keepContentsMounted={true} ref={modal}>
          <IonDatetime
            id='datetime'
            placeholder=''
            presentation='date'
            color='dark'
            defaultValue={props.value}
            value={props.value}
            onIonChange={(e) => {
              _dirty(true);
              if (props.required && e.target.value === '') {
                props.validation({ valid: false, error });
              } else {
                props.validation({ valid: true, error });
              }
              props.onChange(e.target.value);
            }}
            className='mw-calender'
          />
          <div className='mw-full-width'>
            <div className='mw-width-100'>
              <IonButton
                onClick={() => {
                  modal.current?.dismiss();
                }}
                color='light'
                expand='full'
                style={{ width: '100%', minWidth: '100%' }}
              >
                OK
              </IonButton>
            </div>
          </div>
        </IonModal>
        {props.showValidation === true && error && props.required && dirty && !props.value && (
          <IonLabel className='error_text' color='danger'>
            {error}
          </IonLabel>
        )}
      </div>
    );
  },
  Search(props: SearchProps) {
    return (
      <>
        <div
          style={{
            border: '1px solid black',
            borderRadius: 10,
            padding: 4,
            paddingTop: 6,
            height: 38,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              borderRadius: 10,
              overflow: 'hidden',
              marginTop: -6,
              paddingTop: 8,
              paddingLeft: 8,
            }}
          >
            <i className='icon bi-search' slot='start'></i>
            <input
              style={{
                border: 'none',
                outline: 'none',
                marginLeft: 10,
                width: 'calc(100% - 50px)',
              }}
              placeholder='Search'
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
            />
          </div>
        </div>
      </>
    );
  },
  Checkbox(props: CheckboxProps) {
    const [dirty, _dirty] = useState(false);
    const [error, _error] = useState(props.error || props.label + ' is required');
    return (
      <div style={{ paddingTop: 10, marginBottom: 16 }}>
        <IonCheckbox
          style={{}}
          value={props.value}
          onIonChange={(e) => {
            _dirty(true);
            if (props.required && e.target.value === '') {
              props.validation({ valid: false, error });
            }
            props.onChange(e.target.checked);
          }}
        />
        {props.label && props.label !== '' && (
          <div style={{ paddingLeft: 40, marginTop: -25 }}>
            <IonLabel>
              {props.label}
              {props.required && '*'}
            </IonLabel>
          </div>
        )}
        {props.showValidation === true && error && props.required && dirty && !props.value && (
          <IonLabel className='error_text' color='danger'>
            {error}
          </IonLabel>
        )}
      </div>
    );
  },
  ItemDollars(props: ItemDollarsProps) {
    return (
      <div
        style={{
          width: 100,
          border: '1px solid black',
          overflow: 'hidden',
          borderRadius: 6,
          paddingLeft: 5,
          boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.75)',
        }}
      >
        <div style={{ display: 'inline-block' }}>$ &nbsp; </div>
        <input
          max={props.max}
          className='ion-item-input-money'
          type='number'
          placeholder='$0.00'
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          style={{ display: 'inline-block' }}
        />
      </div>
    );
  },
  VerifyAddress(props: VerifyAddressProps) {
    const [address, _address] = useState<any>(props.value);

    const history = useHistory();

    return (
      <div style={{ textAlign: 'left', marginTop: 10 }}>
        <IonLabel>
          <b>{props.label}</b>
        </IonLabel>
        <div>
          {address && address !== '' && (
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                textAlign: 'left',
              }}
            >
              <div style={{ display: 'inline-block', width: 'calc(100% - 100px)' }}>
                <div style={{}}>
                  <b>{address.name}</b>
                </div>
                <div>{address.address1}</div>
                {address.address2 && <div>{address.address2}</div>}
                <div>{address.cityStateZip}</div>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  textAlign: 'right',
                  fontWeight: 500,
                  padding: '0px 10px 10px 10px',
                  width: 100,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  props.editAddress();
                  setTimeout(() => {
                    history.push('/address/' + address.id);
                  }, 500);
                }}
              >
                Edit
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
};

export default MaxwellForm;
