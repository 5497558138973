import { IonCol, IonGrid, IonRow } from '@ionic/react';

import '../Shared/theme/style.css';

interface Props {
  list: Array<{ icon: any; complete?: boolean; clicked?: () => void }>;
}

export default function WizardProgress(props: Props) {
  const margin = 100 / props.list.length;

  function List() {
    return props.list.map((item, index) => {
      return (
        <IonCol key={index} style={{ textAlign: 'center' }}>
          <div
            style={{
              borderRadius: 4,
              width: 30,
              height: 30,
              border: '2px solid black',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: item.complete ? 'pointer' : 'default',
              verticalAlign: 'top',
              color: item.complete ? 'white' : 'black',
              backgroundColor: item.complete ? 'black' : 'white',
              fontSize: 20,
            }}
            onClick={item.clicked}
          >
            <i className={'bi ' + item.icon}></i>
          </div>
        </IonCol>
      );
    });
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <IonGrid>
          <IonRow>{List()}</IonRow>
        </IonGrid>
      </div>
      <div
        style={{
          marginLeft: margin / 2 + '%',
          width: 'calc(100% - ' + margin + '%)',
          height: 4,
          marginTop: -27,
          marginBottom: 40,
          backgroundColor: 'black',
        }}
      ></div>
    </div>
  );
}
