import { IonButton, IonContent, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import ImageService from '../Shared/services/ImageService';
import NotificationService from '../Shared/services/NotificationService';

export default function Notification() {
  const [announcement, _announcement] = useState<any>();
  const history = useHistory();
  const { id } = useParams<any>();

  useEffect(() => {
    NotificationService.getNotification(id).then((res) => {
      _announcement(res.data);
      // set all announcements to seen
      // if has not been seen
      if (!res.data.hasSeenAnnouncement) {
        NotificationService.notificationRead(id);
      }
    });
  }, [id]);

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content '>
        {announcement && (
          <div className='main-container'>
            {announcement.picture && (
              <div
                style={{
                  backgroundImage: `url(${ImageService.Url(announcement.picture)})`,
                  height: 300,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              ></div>
            )}
            <div style={{ padding: 24, paddingTop: 44 }}>
              <h2>{announcement.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: announcement.body }} />

              <br />
              <br />
              {announcement.link &&
                announcement.link.split(',').map((item: string, index: number) => (
                  <IonButton
                    style={{ marginBottom: 16 }}
                    key={index}
                    onClick={() => {
                      if (item.includes('://')) {
                        window.open(item, '_blank');
                      } else {
                        history.push(item);
                      }
                    }}
                    expand='block'
                    color='light'
                  >
                    {announcement.linkText && announcement.linkText.split(',')[index]
                      ? announcement.linkText.split(',')[index]
                      : 'View'}
                  </IonButton>
                ))}
            </div>
            <IonButton
              expand='block'
              color='dark'
              size='small'
              onClick={() => {
                history.goBack();
              }}
            >
              Done
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
