interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface StructuredAddress {
  address1: string;
  address2?: string | null;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

export function extractAddress(
  components: AddressComponent[],
  description: string,
): StructuredAddress {
  let streetNumber: string | null = null;
  let route: string | null = null;
  let address2: string | null = null;
  let city = '';
  let state = '';
  let zipCode = '';
  let country = '';

  components.forEach((component) => {
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    }
    if (component.types.includes('route')) {
      route = component.long_name;
    }
    if (component.types.includes('subpremise')) {
      address2 = component.long_name;
    }
    if (component.types.includes('locality')) {
      city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      state = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      zipCode = component.long_name;
    }
    if (component.types.includes('country')) {
      country = component.short_name;
    }
  });

  // Extract street number from description if not found in components
  if (!streetNumber && description) {
    const match = description.match(/^\d+\s/);
    if (match) {
      streetNumber = match[0].trim();
    }
  }

  let address1 = '';
  if (streetNumber && route) {
    address1 = `${streetNumber} ${route}`;
  } else if (route) {
    address1 = route;
  }

  return { address1, address2, city, state, zipCode, country };
}
