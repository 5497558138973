import { IonAvatar, IonButton, IonContent, IonPage, IonText } from '@ionic/react';
import { camera } from 'ionicons/icons';
import { useEffect, useState } from 'react';

import '../Shared/theme/style.css';
import ImageService, { MxImg } from '../Shared/services/ImageService';
import memberService from '../Shared/services/MemberService';

import WizardProgress from './WizardProgress';

interface Props {
  prevStep: (id?: number) => void;
  nextStep?: any;
  // handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBPicture(props: Props) {
  const [picture, _picture] = useState<any>(camera);
  const [loading, _loading] = useState<boolean>(false);

  useEffect(() => {
    memberService
      .getMember()
      .then((res) => {
        if (res.data.picture !== null) {
          _picture(res.data.picture);
        } else {
          _picture(camera);
        }
      })
      .then(() => {
        if (picture === null) {
          _picture(camera);
        }
      });
  }, []);

  const Continue = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.nextStep();
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className='main-container'>
        <IonContent>
          <div
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                width: '90%',
                marginRight: 'auto',
                marginLeft: 'auto',
                paddingTop: 30,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: 'bi-telephone',
                    complete: true,
                    clicked: () => props.prevStep(1),
                  },
                  {
                    icon: 'bi-person',
                    complete: true,
                    clicked: () => props.prevStep(2),
                  },
                  {
                    icon: 'bi-house',
                    complete: true,
                    clicked: () => props.prevStep(3),
                  },
                  { icon: 'bi-camera', complete: true },
                  { icon: 'bi-lightning' },
                  { icon: 'bi-list-ul' },
                  { icon: 'bi-person-gear' },
                ]}
              />
              <IonText>
                <h1 style={{ paddingTop: 30 }}>Upload your profile picture.</h1>
                <h3>This is optional, but it helps maintain your safety.</h3>
              </IonText>
              <IonAvatar
                style={{
                  width: 250,
                  height: 235,
                  marginLeft: 'calc(50% - 125px)',
                  marginTop: 40,
                  marginBottom: 20,
                  backgroundColor: 'white',
                }}
              >
                <ImageService.Uploadable
                  enablePreview
                  selectedImage={(e: any) => {
                    if (e.target.files) {
                      _loading(true);
                      _picture(URL.createObjectURL(e.target.files[0]));
                      const formData = new FormData();
                      formData.append('file', e.target.files[0]);
                      memberService.postMemberPicture(formData).then(() => {
                        //console.log(res);
                        _loading(false);
                      });
                    }
                  }}
                >
                  {picture && (
                    <MxImg
                      alt='Silhouette of mountains'
                      src={picture}
                      objectPosition='center'
                      objectFit='cover'
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  )}
                </ImageService.Uploadable>
              </IonAvatar>
              <div className='mw-center' style={{ marginBottom: 20 }}>
                <span>Click to Upload</span>
              </div>

              {picture && picture !== camera && (
                <IonButton disabled={loading} onClick={Continue} expand='block' color='dark'>
                  {loading ? 'Loading...' : 'Continue'}
                </IonButton>
              )}
              {picture && picture === camera && (
                <IonButton
                  onClick={Continue}
                  expand='block'
                  color='dark'
                  class='ion-color ion-color-dark ios button button-block button-solid ion-activatable ion-focusable'
                >
                  Skip
                </IonButton>
              )}
              <IonButton
                className='backButton'
                expand='block'
                onClick={Back}
                color='light'
                style={{ marginTop: 20 }}
              >
                Back
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
