import { IonButton, IonContent, IonIcon, IonModal } from '@ionic/react';
import { animated, useSpring } from '@react-spring/web';
import { cardOutline, giftOutline, logoUsd } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import EmployerService from '../../Shared/services/EmployerService';
import RegalosService from '../../Shared/services/RegalosService';

import Regalos_Sent_Modal from './Regalos_Sent_Modal';

interface Props {
  isOpen: boolean;
  dismissed: () => void;
  amount: number;
  wallets: any;
  note?: string;
  isPublic: boolean;
  member: any;
}

export default function Regalos_Select_Wallet(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [tall, setTall] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [regalosTags, setRegalosTags] = useState<any>([]);
  const [regalosGenericTags, setRegalosGenericTags] = useState<any>([]);
  const [regalos, setRegalos] = useState<any>([]);
  const [canDismiss, setCanDismiss] = useState(false);

  // id param from url
  const { id } = useParams<{ id: string }>();

  // format amount as usd currency
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    console.log('the wallets', props.wallets);
    EmployerService.regalosTags().then((res) => {
      console.log('regalos tags', res.data);
      setRegalosTags(res.data);
    });
    EmployerService.regalosGenericTags().then((res) => {
      console.log('regalos tags', res.data);
      setRegalosGenericTags(res.data);
    });
  }, [props.wallets]);

  const [spring, api] = useSpring(
    () => ({
      from: { y: 0, height: '50vh' },
      to: { y: 0, height: '50vh' },
    }),

    [],
  );

  function toggle() {
    if (isOpen && tall) {
      api.start({
        from: { height: '85vh', y: 0 },
        to: { height: 'calc( 0vh )', y: 500 },
      });
      setTimeout(() => {
        props.dismissed();
        setIsOpen(false);
        setTall(false);
      }, 400);
    } else if (isOpen && !tall) {
      api.start({
        from: { height: 'calc( 50vh)', y: 0 },
        to: { height: 'calc( 0vh )', y: 500 },
      });
      setTimeout(() => {
        props.dismissed();
        setIsOpen(false);
        setTall(false);
      }, 400);
    } else {
      api.start({
        from: { height: 'calc( 50vh )', y: 500 },
        to: { height: 'calc( 50vh)', y: 0 },
      });
    }
  }

  function expand() {
    if (!tall) {
      api.start({
        from: { height: '50vh' },
        to: { height: '85vh' },
      });
      setTall(true);
    } else {
      api.start({
        from: { height: '85vh' },
        to: { height: '50vh' },
      });
      setTall(false);
    }
  }

  function WalletItem(walletProp: any) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            paddingLeft: 20,
            paddingRight: 20,
          }}
          onClick={() => {
            if (props.amount <= walletProp.wallet.balance * 0.01) {
              const payload = {
                amount: props.amount * 100,
                fromBalance: walletProp.wallet.isBalance || false,
                fromRegalos: walletProp.wallet.isRegalos || false,
                budgetId: walletProp.wallet.id ? walletProp.wallet.id : 0,
                title: 'Regalos',
                note: props.note,
                receiverId: id,
                isPublic: props.isPublic,
              };
              setShowModal(true);
              RegalosService.postRegalosFromWallet(payload).then((res) => {
                console.log('regalos sent', res.data);
                setRegalos(res.data);
              });

              // alert("Sending is disabled for testing");
            }
          }}
        >
          <div
            style={{
              padding: 12,
              fontSize: 35,
              paddingTop: 18,
            }}
          >
            {walletProp.wallet.isRegalos && <IonIcon icon={giftOutline} />}
            {walletProp.wallet.isBalance && <IonIcon icon={cardOutline} />}
            {walletProp.wallet.id != null && <IonIcon icon={logoUsd} />}
          </div>
          <div style={{ flex: 1 }}>
            <div
              style={{
                paddingTop: 18,
                fontWeight: 500,
              }}
            >
              {walletProp.wallet.name}
            </div>
            <div
              style={{
                fontSize: 12,
              }}
            >
              {walletProp.wallet.description}
            </div>
          </div>
          <div
            style={{
              paddingTop: 18,
              fontWeight: 500,
              color: props.amount <= walletProp.wallet.balance * 0.01 ? 'black' : 'red',
            }}
          >
            {formatter.format(walletProp.wallet.balance * 0.01)}
          </div>
        </div>
        <div
          style={{
            marginLeft: 20,
          }}
          className='lines'
        ></div>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <IonButton
          disabled={props.amount < 0.01 || !props.note}
          color='dark'
          onClick={() => {
            setIsOpen(true);
            toggle();
          }}
        >
          Send
        </IonButton>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          zIndex: 99,
          display: isOpen ? 'flex' : 'none',
          justifyContent: 'end',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,.1)',
            zIndex: 99,
          }}
          onClick={() => {
            toggle();
          }}
        ></div>

        <animated.div
          style={{
            width: '100%',
            backgroundColor: 'white',
            maxWidth: 800,
            alignSelf: 'center',
            justifySelf: 'end',
            border: '2px solid black',
            borderBottom: '0px solid black',
            borderRadius: '10px 10px 0px 0px',
            overflow: 'hidden',
            boxShadow: '0px -1px 14px 0px rgba(0,0,0,0.75)',
            zIndex: 999,
            ...spring,
          }}
        >
          {' '}
          <div
            style={{ height: 22, backgroundColor: '#fcfcfc' }}
            onClick={() => {
              expand();
            }}
          >
            <div
              style={{
                height: 6,
                width: 60,
                backgroundColor: 'black',
                borderRadius: 10,
                margin: 10,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            ></div>
          </div>
          <IonContent>
            <div
              style={{
                display: 'flex',
                padding: 20,
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <h3>Select a wallet.</h3>
              </div>
              <div>
                <h5>Total: ${props.amount}</h5>
              </div>
            </div>
            {props.wallets &&
              props.wallets.length > 0 &&
              props.wallets.map((wallet: any) => <WalletItem key={wallet.id} wallet={wallet} />)}

            <IonButton
              style={{
                marginTop: 30,
                marginBottom: 80,
              }}
              color='light'
              expand='block'
              onClick={() => {
                toggle();
              }}
            >
              Cancel
            </IonButton>
          </IonContent>
        </animated.div>
        <IonModal
          canDismiss={canDismiss}
          className='regalos-send-modal'
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
        >
          <IonContent>
            {regalosTags && regalosGenericTags && (
              <Regalos_Sent_Modal
                onSelect={(titleId: number) => {
                  setCanDismiss(true);
                  console.log('tag selected');
                  if (regalos && regalos.id) {
                    RegalosService.UpdateRegalosTitle(regalos.id, titleId).then(() => {
                      setShowModal(false);
                      window.location.href = '/regalos';
                    });
                  }
                }}
                regalosTags={regalosTags}
                regalosGenericTags={regalosGenericTags}
                member={props.member}
              />
            )}
          </IonContent>
        </IonModal>
      </div>
    </>
  );
}
