import { IonItem, IonLabel, IonList, IonSearchbar } from '@ionic/react';
import { useState, useEffect } from 'react';

import AddressService from '../../Shared/services/AddressService';

import './style.css';

import { StructuredAddress, extractAddress } from '../../Shared/services/GooglePlacesService';

interface DebouncedAutocompleteProps {
  label: string;
  placeholder?: string;
  value: string;
  required: boolean;
  validation: (data: { valid: boolean; error: string }) => void;
  onChange: (value: StructuredAddress) => void;
  error?: string;
}

export function DebouncedAutocomplete(props: DebouncedAutocompleteProps) {
  const [inputValue, setInputValue] = useState(props.value ?? '');
  const [autocompleteResults, setAutocompleteResults] = useState<any[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (inputValue) {
      setIsLoading(true);
      AddressService.autoCompleteAddress(inputValue).then((res) => {
        setAutocompleteResults(res.data.predictions);
        setIsLoading(false);
      });
    }
  }, [inputValue]);

  useEffect(() => setInputValue(props.value ?? ''), [props.value]);

  return (
    <div style={{ paddingTop: 0, marginBottom: 18 }}>
      {props.label && (
        <IonLabel>
          {props.label}
          {props.required && '*'}
        </IonLabel>
      )}
      <IonSearchbar
        debounce={300}
        value={inputValue}
        onIonInput={(e) => {
          setInputValue(e.target.value!);
          props.onChange({
            address1: e.target.value!,
          } as StructuredAddress);
        }}
        placeholder={props.placeholder || 'Enter ' + props.label}
        className='mw-input address-searchbar'
        onFocus={() => setIsFocused(true)}
        onBlur={() =>
          setTimeout(() => {
            setIsFocused(false);
          }, 300)
        }
        showClearButton='never'
        searchIcon={undefined}
      />
      {!isLoading && isFocused && autocompleteResults.length > 0 && (
        <IonList
          style={{
            position: 'absolute',
            width: 'calc(100% - 88px)',
            zIndex: 999,
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
            borderRadius: 5,
          }}
        >
          {autocompleteResults.map((result, index) => (
            <IonItem
              key={index}
              className='address-item'
              onClick={() => {
                AddressService.getAddressDetails(result.place_id).then((place) => {
                  const address = extractAddress(
                    place.data.result.address_components,
                    result.description,
                  );
                  setInputValue(address.address1);
                  props.onChange(address);
                });
              }}
            >
              {result.description}
            </IonItem>
          ))}
        </IonList>
      )}
    </div>
  );
}
