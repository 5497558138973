import { IonButton, IonContent, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import VIPService from '../Shared/services/VIPService';

import DependentItem from './DependentItem';

export default function Dependents() {
  const [segment, _segment] = useState<any>('card');
  const history = useHistory();
  const [vips, _vips] = useState<any>();

  useEffect(() => {
    // call api or anything
    VIPService.getVIPs().then((res) => {
      _vips(res.data);
    });
  }, []);

  function isChild(element: any, index: any, array: any) {
    return element.child === true && element.dependent === true;
  }

  function isAdult(element: any, index: any, array: any) {
    return element.child === false && element.dependent === true;
  }

  function isOther(element: any, index: any, array: any) {
    return element.dependent === false;
  }

  function vipList(vips: any) {
    if (vips !== null) {
      const vipdata = _.orderBy(vips, ['name', 'asc']);
      return vipdata.map((vip: any, index: number) => {
        return <DependentItem dependent={vip} index={index} key={index} />;
      });
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content '>
        <div className='main-container leave-room-for-menu ion-padding'>
          <div style={{ padding: 24 }}>
            {vips && vips.filter(isChild)[0] && (
              <>
                <h3> Child Dependents</h3>
                {vipList(vips.filter(isChild))}
              </>
            )}

            {vips && vips.filter(isAdult)[0] && (
              <>
                <h3> Adult Dependents</h3>
                {vipList(vips.filter(isAdult))}
              </>
            )}

            {vips && vips.filter(isOther)[0] && (
              <>
                <h3> Other Dependents</h3>
                {vipList(vips.filter(isOther))}
              </>
            )}
          </div>
          <IonButton
            onClick={() => {
              history.push('/dependent');
            }}
            color='light'
            expand='block'
          >
            <i className='icon bi-plus-square' /> &nbsp; Add Dependant
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}
