import { IonCol, IonGrid, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import Card from '../Shared/Card';
import SelectOptions from '../Shared/SelectOptions';
import FavoriteService from '../Shared/services/FavoriteService';
import isMobile from '../Shared/services/IsMobile';
import MaxwellForm from '../Shared/services/MaxwellForm';
import { memberState } from '../Shared/services/MemberService';
import partnerService, { favoritesPartnersState } from '../Shared/services/PartnerService';
import '../Shared/theme/style.css';

interface Props {
  partners: any;
}

export default function PartnerList(props: Props) {
  const [member, _member] = useRecoilState<any>(memberState);
  const [partnerType, _partnerType] = useState(-1);
  const [partnerTypes, _partnerTypes] = useState<any>();
  const [search, _search] = useState('');
  const history = useHistory();
  const [favorites, _favorites] = useRecoilState<any>(favoritesPartnersState);

  useEffect(() => {
    FavoriteService.getFavorites('Partner').then((res) => {
      _favorites(res.data);
    });
    partnerService.getPartnerTypes().then((res) => {
      //TODO
      // console.log(res.data);

      _partnerTypes(res.data);
    });
    //TODO
    // .then((res) => {
    // console.log(partnerTypes);
    // });
  }, []);

  function favorited() {
    FavoriteService.getFavorites('Partner').then((res) => {
      _favorites(res.data);
    });
  }

  function filterPartners(arr: any, typeId: number, search: string) {
    let fArr = arr;
    fArr = filterType(fArr, typeId);
    fArr = filterSearch(fArr, search);
    return fArr;
  }

  function filterType(arr: any, typeId: number) {
    if (typeId > 0) {
      return arr.filter((el: any) => el.partnerTypeId === typeId);
    } else {
      if (typeId === -2) {
        return arr.filter((el: any) => favorites.favIds.includes(el.id));
      }
      return arr;
    }
  }

  function filterSearch(arr: any, search: string) {
    if (search !== '') {
      return arr.filter((el: any) => el.title.toLowerCase().includes(search.toLowerCase()));
    } else {
      return arr;
    }
  }

  function ParList(marks: any) {
    if (marks !== null) {
      const mardata = _.orderBy(marks, ['name', 'asc']);
      return mardata.map((mark: any, index: number) => {
        let tStr = '';
        if (mark.inApp === true) {
          tStr = 'Off App';
        }

        if (member && member.locationId) {
          if (mark.locations != null && mark.locations.length > 0) {
            let found = false;
            for (const m of mark.locations) {
              if (m.id === member.locationId) {
                found = true;
              }
            }
            if (found === false) {
              console.log(mark.locations);
              return null;
            }
          }
        }

        return (
          <Card
            key={mark.id + index}
            id={mark.id}
            tag={tStr}
            maxWidth={270}
            minWidth={140}
            width={'calc(50% - 20px)'}
            height={isMobile() ? 170 : 200}
            isFlex={true}
            title={mark.title}
            picture={mark.picture}
            showFavorite={true}
            favorite={favorites.favIds?.includes(mark.id)}
            categoryId={1}
            amount={mark.amount}
            clicked={() => {
              history.push('/partner/' + mark.id);
            }}
            favorited={() => {
              favorited();
            }}
          />
        );
      });
    }
  }

  return (
    <div className=''>
      <IonGrid className='ion-no-padding'>
        <div style={{ display: 'flex' }}>
          <div key={1} style={{ flex: 1 }}>
            <div
              style={{
                fontSize: 20,
                paddingTop: 8,
                paddingLeft: 24,
                fontWeight: 500,
              }}
            >
              Brands
            </div>
          </div>
          <div key={2}>
            <div
              style={{
                float: 'right',
                border: '1px solid black',
                borderBottom: '3px solid black',
                borderRight: '2px solid black',
                padding: 3,
                borderRadius: 10,
                margin: 10,
                height: 30,
                width: 200,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  marginTop: -10,
                  border: 'unset !important',
                  overflow: 'hidden',
                }}
              >
                {isMobile() ? (
                  <IonSelect
                    style={{
                      textAlign: 'left',
                      width: '100%',
                      backgroundColor: 'transparent',
                    }}
                    placeholder='Filter By Type'
                    interface={'action-sheet'}
                    onIonChange={(e) => {
                      //TODO
                      // console.log(e.target.value);
                      _partnerType(e.target.value);
                    }}
                  >
                    <IonSelectOption key={0} value={-1}>
                      All
                    </IonSelectOption>{' '}
                    <IonSelectOption key={111} value={-2}>
                      Favorites
                    </IonSelectOption>
                    {partnerTypes && <SelectOptions options={partnerTypes} />}
                  </IonSelect>
                ) : (
                  <select
                    style={{
                      paddingTop: 10,
                      height: '100%',
                      border: '0px solid black',
                      textAlign: 'left',
                      width: '100%',
                      backgroundColor: 'transparent',
                    }}
                    placeholder='Filter By Type'
                    onChange={(e) => {
                      //TODO
                      console.log(e.target.value);
                      _partnerType(parseInt(e.target.value));
                    }}
                  >
                    <option value={-1}>All</option>
                    <option value={-2}>Favorites</option>
                    {partnerTypes &&
                      partnerTypes.map((type: any) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
        <IonRow>
          <IonCol style={{ padding: '0px 10px' }}>
            <MaxwellForm.Search
              value={search}
              onChange={(e) => {
                _search(e);
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <div>
              <div className='padding'>
                <div
                  style={{
                    paddingTop: 0,
                    marginRight: -16,
                    textAlign: 'center',
                  }}
                >
                  {ParList(filterPartners(props.partners, partnerType, search))}
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
