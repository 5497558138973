import axios from 'axios';
import { atom } from 'recoil';

const APIURL = process.env.REACT_APP_API_HOST;

export const vipsState = atom({
  key: 'vipsState', // unique ID (with respect to other atoms/selectors)
  default: {},
});

const vipService = {
  async getVIPs() {
    return axios.get(`${APIURL}/VIPs/GetVIPs`);
  },
  async getVIP(id: number) {
    return axios.get(`${APIURL}/VIPs/GetVIP/${id}`);
  },
  async getSpecialOccasions(vipid: number) {
    return axios.get(`${APIURL}/SpecialOccasions/GetSpecialOccasions/${vipid}`);
  },
  async postSpecialOccasion(specoc: any) {
    return axios.post(`${APIURL}/SpecialOccasions/PostSpecialOccasion`, specoc);
  },
  async putSpecialOccasion(id: number, specoc: any) {
    return axios.put(`${APIURL}/SpecialOccasions/PostSpecialOccasion/${id}`, specoc);
  },
  async deleteSpecialOccasion(id: number) {
    return axios.delete(`${APIURL}/SpecialOccasions/DeleteSpecialOccasion/${id}`);
  },
  async putVIP(id: number, vip: any) {
    return axios.put(`${APIURL}/VIPs/PutVIP/${id}`, vip);
  },
  async postVIP(vip: any) {
    return axios.post(`${APIURL}/VIPs/PostVIP`, vip);
  },
  async deleteVIP(id: number) {
    return axios.delete(`${APIURL}/VIPs/DeleteVIP/${id}`);
  },
};

export default vipService;
