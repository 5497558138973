import { IonButton, IonContent, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import HeadSubpage from '../Nav/HeaderSubpage';
import AnnouncementService, { announcementsState } from '../Shared/services/AnnouncementService';

import AnnouncementItem from './AnnouncementItem';

export default function Announcements() {
  const [segment, _segment] = useState<any>('card');
  const history = useHistory();
  const [showAll, _showAll] = useState<any>(false);
  const [announcements, _announcements] = useRecoilState<any>(announcementsState);

  useEffect(() => {
    // call api or anything
    AnnouncementService.getAnnouncements().then((res) => {
      _announcements(res.data);
      //console.log(res.data);
    });
  }, []);

  function AnnouncementList(announcements: any) {
    if (announcements !== null) {
      const actdata = _.orderBy(announcements, ['date'], ['desc']);
      return actdata
        .filter((x) => {
          return !x.hasSeenAnnouncement || showAll;
        })
        .map((announcement: any, index: number) => {
          return (
            <AnnouncementItem
              key={index}
              id={announcement.id}
              title={announcement.title}
              date={announcement.updatedDatemmddyyyy}
              picture={announcement.picture}
              clicked={() => {
                history.push('/announcement/' + announcement.id);
              }}
            />
          );
        });
    }
  }

  function showEmpty() {
    const notSeen =
      (announcements && announcements.filter((x: any) => !x.hasSeenAnnouncement).length) || 0;
    return showAll === false && notSeen === 0 && announcements;
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <div className='main-container leave-room-for-menu'>
          <div style={{ padding: 24 }}>
            <h3> Your Announcements</h3>
            {(announcements && AnnouncementList(announcements)) || (
              <Skeleton height={60} style={{ marginBottom: 12 }} count={10} />
            )}
          </div>
          {showEmpty() && (
            <div style={{ padding: 64, textAlign: 'center' }}>
              <img style={{ maxWidth: 250 }} src='/assets/partyhat.png' />
              <h3 style={{ fontWeight: 'bold' }}>YOU ARE UP TO DATE</h3>
              When you have new notifications they will appear here.
              <div style={{ marginTop: 40 }}>
                <IonButton
                  onClick={() => {
                    _showAll(true);
                  }}
                  color='light'
                >
                  Show All
                </IonButton>
                {showAll && 'show all'}
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}
