import { IonIcon, IonLabel } from '@ionic/react';
import { heart } from 'ionicons/icons';
import { useHistory } from 'react-router';

import ImageService from '../Shared/services/ImageService';
import { Member } from '../Shared/types/member';

interface Props {
  member: Member;
  link: string;
}

export default function MembersItem(props: Props) {
  const history = useHistory();

  return (
    <div
      style={{
        paddingBottom: 10,
        marginTop: 10,
        borderBottom: '1px solid #ccc',
      }}
      onClick={() => {
        history.push(props.link);
      }}
    >
      <div style={{ display: 'inline-block' }}>
        <div
          style={{
            width: 50,
            height: 50,
            display: 'inline-block',
            backgroundImage: 'url(' + ImageService.Url(props.member.picture) + ')',
            borderRadius: 10,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginLeft: 10,
            verticalAlign: 'top',
            textAlign: 'center',
            fontSize: 10,
            fontWeight: 'bold',
            paddingTop: 20,
            boxShadow: '0px 0px 5px #00000033',
            border: '1px solid black',

            zIndex: 1,
          }}
          slot='start'
        ></div>
      </div>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'top',
          paddingLeft: 14,
          width: 'calc(100% - 110px)',
        }}
      >
        <IonLabel>
          {props.member.firstName} {props.member.lastName}
          <br /> &nbsp;
        </IonLabel>
      </div>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'top',
          paddingLeft: 14,
          textAlign: 'right',
          width: 50,
          fontSize: 18,
        }}
      >
        {props.member.isFavorite && props.member.isFavorite === true && <IonIcon icon={heart} />}
      </div>
    </div>
  );
}
