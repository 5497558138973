import { IonAvatar, IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { useHistory } from 'react-router';

import '../Shared/theme/style.css';

import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useInView, animated } from '@react-spring/web';
import { MxImg } from '../Shared/services/ImageService';
import { memberState } from '../Shared/services/MemberService';
import RegalosService from '../Shared/services/RegalosService';
import { Member } from '../Shared/types/member';
import { Regalos } from '../Shared/types/regalos';

interface Props {
  activity: Regalos;
  onUpdated: () => void;
}

export default function RegalosItem(props: Props) {
  const history = useHistory();
  const [hearted, _hearted] = useState(false);
  const [member, _member] = useRecoilState<any>(memberState);

  useEffect(() => {
    _hearted(props.activity.isHearted);
  }, []);

  function HeartedList() {
    return props.activity.heartedMembers.map((member: Member, index: number) => {
      return (
        <IonAvatar
          key={index}
          style={{
            width: 30,
            height: 30,
            marginLeft: -8,
            display: 'inline-block',
          }}
        >
          {member && (
            <MxImg
              alt='Silhouette of mountains'
              src={
                member.picture
                  ? member.picture
                  : 'https://api.maxwell.community/api/Image/File/DefaultProfilePicture.png'
              }
            />
          )}
        </IonAvatar>
      );
    });
  }

  function isMine() {
    return (
      props.activity.senderMember?.id === member?.id ||
      props.activity.receiverMember?.id === member?.id
    );
  }

  const [annRef, inView] = useInView(
    () => ({
      from: {
        scale: 0.8,
      },
      to: {
        scale: 1,
      },
    }),
    {
      rootMargin: '0% 0%',
    },
  );

  return (
    <div style={{ paddingTop: 24, backgroundColor: '#fcfcfc' }}>
      <animated.div
        ref={annRef}
        onClick={() => {
          history.push('/regalos-message/' + props.activity.id, props.activity);
        }}
        style={{ width: 65, display: 'inline-block', ...inView }}
      >
        <IonAvatar style={{ width: 55, height: 55 }} slot='start'>
          {props.activity.senderMember && (
            <MxImg
              alt='Silhouette of mountains'
              src={
                props.activity.senderMember.picture
                  ? props.activity.senderMember.picture
                  : 'https://api.maxwell.community/api/Image/File/DefaultProfilePicture.png'
              }
            />
          )}
        </IonAvatar>
      </animated.div>
      <div
        style={{
          width: 'calc(100% - 81px)',
          display: 'inline-block',
          verticalAlign: 'top',
        }}
      >
        <>
          <IonGrid className='ion-no-padding'>
            <IonRow
              onClick={() => {
                history.push('/regalos-message/' + props.activity.id, props.activity);
              }}
            >
              <IonCol size='9'>
                <b> {props.activity.senderMember?.preferredName}</b> to&nbsp;
                <b>{props.activity.receiverMember?.preferredName}</b> &nbsp;
              </IonCol>
              <IonCol size='3' style={{ fontWeight: 'bold', textAlign: 'right' }}>
                {isMine() && (
                  <>${props.activity.amount && (props.activity.amount / 100).toFixed(2)}</>
                )}
              </IonCol>
            </IonRow>
            <IonRow
              onClick={() => {
                history.push('/regalos-message/' + props.activity.id, props.activity);
              }}
              className='ion-no-padding'
            >
              <IonCol className='ion-no-padding'>
                <div className='sub-label'>{moment(props.activity.createdDate).fromNow()}</div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div style={{ fontWeight: 'bold' }} className='sub-label'>
                  {!props.activity.isPublic && (
                    <i style={{ fontSize: 10 }} className='fas fa-eye-slash'></i>
                  )}
                  &nbsp; {props.activity.title}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      </div>
      <IonItem lines='full'>
        <IonGrid>
          <IonRow
            onClick={() => {
              history.push('/regalos-message/' + props.activity.id, props.activity);
            }}
          >
            <IonCol>
              {props.activity && props.activity.note && props.activity.note.length > 64
                ? props.activity.note
                    .substring(0, 64)
                    .slice(0, props.activity.note.substring(0, 64).lastIndexOf(' '))
                : props.activity.note}

              {props.activity && props.activity.note && props.activity.note.length > 60 && '...'}
            </IonCol>
          </IonRow>
          <IonRow style={{ paddingTop: 8 }}>
            <IonCol size='2'>
              <div className='inline' style={{ zIndex: 100 }}>
                {props.activity.isHearted || hearted ? (
                  <i
                    onClick={() => {
                      // remove member from heartedMembers
                      const newRegalos: any = structuredClone(props.activity);
                      newRegalos.isHearted = false;
                      const newMembers = [];
                      for (const m of newRegalos.heartedMembers) {
                        if (m.id && m.id != member.id) {
                          newMembers.push(m);
                        }
                      }

                      newRegalos.heartedMembers = newMembers;

                      // _regalos(newRegalos);
                      props.onUpdated();
                      setTimeout(() => {
                        _hearted(false);
                        props.onUpdated();
                      }, 100);
                      RegalosService.putRegalosUnheart(props.activity.id);
                    }}
                    className='icon bi-heart-fill'
                  ></i>
                ) : (
                  <i
                    onClick={() => {
                      // add member to heartedMembers
                      console.log('newRegalos', props.activity);
                      const newRegalos = structuredClone(props.activity);
                      newRegalos.isHearted = true;
                      newRegalos.heartedMembers.push(member);

                      // _regalos(newRegalos);

                      props.onUpdated();
                      setTimeout(() => {
                        _hearted(true);
                        props.onUpdated();
                      }, 100);

                      RegalosService.putRegalosHeart(props.activity.id);
                    }}
                    className='icon bi-heart'
                  ></i>
                )}
              </div>
            </IonCol>
            <IonCol style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {props.activity && props.activity.heartedMembers && HeartedList()}
              <div
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                  verticalAlign: 'bottom',
                  fontSize: 10,
                  display: 'inline-block',
                }}
              >
                &nbsp;
                {props.activity.heartedMembers?.length}
                &nbsp;likes
              </div>
            </IonCol>
            <IonCol
              size='3'
              style={{
                textAlign: 'right',
                fontWeight: 'bold',
                fontSize: 10,
                alignSelf: 'flex-end',
              }}
            >
              {props.activity.messages?.length} comments
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </div>
  );
}
