import { IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import EmployerService from '../../Shared/services/EmployerService';
import ImageService from '../../Shared/services/ImageService';
import isMobile from '../../Shared/services/IsMobile';
import RegalosService from '../../Shared/services/RegalosService';

export default function Regalos_Sent_Page() {
  const [regalosTags, setRegalosTags] = useState<any>([]);
  const [regalosGenericTags, setRegalosGenericTags] = useState<any>([]);
  const [regalos, setRegalos] = useState<any>([]);

  // id param from url
  const { id } = useParams<any>();

  useEffect(() => {
    EmployerService.regalosTags().then((res) => {
      console.log('regalos tags', res.data);
      setRegalosTags(res.data);
    });
    EmployerService.regalosGenericTags().then((res) => {
      console.log('regalos tags', res.data);
      setRegalosGenericTags(res.data);
    });
    RegalosService.getRegalos(parseInt(id)).then((res) => {
      console.log('regalos', res.data);
      setRegalos(res.data);
    });
  }, []);

  function OptionBox(options: { title: string; image: string; color: string }) {
    return (
      <div
        style={{
          padding: 8,
          flex: 1,
          width: 160,
          maxWidth: 140,
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            alignSelf: 'end',
            justifySelf: 'end',
            width: 120,
            height: 120,
            border: '1px solid black',
            borderRadius: 10,
            margin: 'auto',
            backgroundImage: 'url(' + ImageService.Url(options.image) + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundColor: options.color,
          }}
          onClick={() => {
            // RegalosService.UpdateRegalosTitle(id, options.title).then((res) => {
            //   history.push("/regalos");
            // });
          }}
        ></div>
        <div
          style={{
            fontSize: 12,
            flex: 1,
            justifyContent: 'end',
            paddingLeft: 0,
            paddingRight: 10,
            paddingTop: 4,
            flexDirection: 'column-reverse',
          }}
        >
          {options.title}
        </div>
      </div>
    );
  }

  return (
    <IonPage>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          maxWidth: 900,
          backgroundColor: '#fcfcfc',
          borderRadius: 0,
          borderLeft: isMobile() ? '0px solid white' : '2px solid black',
          borderRight: isMobile() ? '0px solid white' : '2px solid black',
          boxShadow: '0px 0px 14px 0px rgba(0,0,0,0.75)',
          padding: 18,
          textAlign: 'center',
          overflow: 'hidden',
          paddingTop: 40,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: 130,
          }}
        >
          {' '}
          <div
            style={{
              border: '2px solid black',
              padding: 16,
              borderRadius: 10,
              height: 120,
              backgroundColor: '#acd5c9',
              position: 'absolute',
              top: 6,
              left: 5,
              width: '100%',
              boxShadow: '0px 0px 14px 0px rgba(0,0,0,0.25)',
            }}
          ></div>
          <div
            style={{
              border: '2px solid black',
              padding: 16,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 0,
              borderRadius: 10,
              height: 120,
              textAlign: 'left',
              backgroundColor: 'white',
              zIndex: 999,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              fontSize: 14,
            }}
          >
            <h4>You made their day!</h4>

            <div style={{ textAlign: 'left' }}>
              One last thing. Why are you recognizing {regalos?.receiverMember?.firstName}?
              <div style={{ paddingTop: 8 }}>Select a reason below.</div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: 'left',
            paddingTop: 40,
            paddingLeft: 10,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Your company values.
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            marginTop: 10,
            marginLeft: -18,
            marginRight: -18,
            paddingLeft: 18,
            paddingRight: 38,
            maxHeight: 'calc(100vh - 190px)',
            overflowX: 'auto',
            overflowY: 'hidden',
            paddingBottom: 10,
          }}
        >
          {regalosTags &&
            regalosTags.map((item: any) => {
              return (
                <OptionBox key={item.id} title={item.title} image={item.image} color={item.color} />
              );
            })}
        </div>
        <div
          style={{
            textAlign: 'left',
            paddingTop: 40,
            paddingLeft: 10,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          More reasons for Regalos.
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            marginTop: 30,
            marginLeft: -18,
            marginRight: -18,
            maxHeight: 'calc(100vh - 190px)',
            overflowX: 'auto',
            overflowY: 'hidden',
            paddingBottom: 10,
            paddingLeft: 18,
            paddingRight: 38,
          }}
        >
          {regalosGenericTags &&
            regalosGenericTags.map((item: any) => {
              return (
                <OptionBox key={item.id} title={item.title} image={item.image} color={item.color} />
              );
            })}
        </div>
      </div>
    </IonPage>
  );
}
