import { IonButton, IonContent, IonIcon, IonPage, IonText } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import '../Shared/theme/style.css';
import { arrowBack } from 'ionicons/icons';
import { MxImg } from '../Shared/services/ImageService';
import memberService from '../Shared/services/MemberService';

interface Props {
  prevStep?: any;
  nextStep?: any;
  values?: any;
  linkable?: boolean;
}

export default function OBThankYou(props: Props) {
  const [member, _member] = useState<any>();

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);
    });
  }, []);

  const history = useHistory();

  const goHome = (e: { preventDefault: () => void }) => {
    memberService
      .putMember({
        onboarded: moment().format('YYYY-MM-DDTHH:mm:ss'),
      })
      .then(() => {
        memberService.acceptTerms().then(() => {
          console.log('Terms accepted');
        });
        e.preventDefault();
        history.push('/home');
      });
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className='main-container'>
        {/* <OBHeader prevStep={props.prevStep} /> */}
        <IonContent>
          <div
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                width: '90%',
                marginRight: 'auto',
                marginLeft: 'auto',
                paddingTop: 30,
              }}
            >
              <IonText>
                {/* <h1 style={{ textAlign: "center" }}>Thank you!</h1> */}
                <div
                  style={{
                    paddingTop: 30,
                    paddingBottom: 30,
                    fontSize: 32,
                    textAlign: 'center',
                  }}
                >
                  All done! Time to start creating some joy.
                </div>
                {/* <h3 style={{ textAlign: "center" }}> */}
              </IonText>
              <MxImg src='ThankYouScrn.png' style={{ width: '100%', maxWidth: 500 }} />
              <div style={{ fontSize: 12 }}>
                <a
                  href='https://stripe.com/legal/issuing/celtic/spend-card-terms-and-disclosures'
                  target='_blank'
                  rel='noreferrer'
                >
                  Cardholder terms and policy
                </a>
              </div>
              {member && (
                <div
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <IonButton onClick={goHome} style={{ marginTop: 20 }} expand='block' color='dark'>
                    Finish Now & Access Your Budget
                  </IonButton>
                  {/* <IonButton
                    onClick={Continue}
                    style={{ marginTop: 20, width: "100%" }}
                    expand="block"
                    color="dark"
                    class="ion-color ion-color-light ios button button-block button-solid ion-activatable ion-focusable"
                  >
                    Answer Additional Questions
                  </IonButton> */}
                  <IonButton
                    style={{ marginTop: 20 }}
                    className='backButton'
                    expand='block'
                    onClick={Back}
                    color='light'
                  >
                    <IonIcon icon={arrowBack} /> Back
                  </IonButton>
                </div>
              )}
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
