import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  useIonViewDidEnter,
} from '@ionic/react';

import '../Shared/theme/style.css';

import { useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import OnboardingService from '../Shared/services/OnboardingService';
import VIPService from '../Shared/services/VIPService';
import { Gender } from '../Shared/types/gender';

type Inputs = {
  firstName: string;
  lastName: string;
  birthdate: string;
  interests: string | null;
  anything: string | null;
  child: boolean;
  dependent: boolean;
  curateGift: boolean;
  pictureId: number;
  memberId: number;
  genderId: number;
  name: string;
  birthdateString: string;
  birthdatemmddyyyy: string;
};

export default function Dependent() {
  const history = useHistory();
  const [genders, _genders] = useState<any>();
  const [showDelete, _showDelete] = useState(false);
  const { id } = useParams<any>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data: any) => {
    const send: any = data;
    console.log('data', data);

    if (id) {
      VIPService.putVIP(id, send).then((res) => {
        history.goBack();
      });
    } else {
      VIPService.postVIP(send).then((res) => {
        history.goBack();
      });
    }
  };

  useIonViewDidEnter(() => {
    // call api or anything
    OnboardingService.getGenders().then((res) => {
      _genders(res.data);
    });

    if (id) {
      VIPService.getVIP(id).then((res) => {
        //console.log(res);
        reset(res.data);
      });
    }
  });

  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='main-container leave-room-for-menu'>
            <IonList>
              <IonItem>
                <IonLabel position='stacked'>First Name*</IonLabel>
                {errors.firstName && errors.firstName.type === 'required' && (
                  <span style={{ color: 'red', fontSize: 'small' }}>This is required</span>
                )}
                <IonInput
                  {...register('firstName', { required: true })}
                  placeholder='Enter First Name'
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position='stacked'>Last Name*</IonLabel>
                {errors.lastName && errors.lastName.type === 'required' && (
                  <span style={{ color: 'red', fontSize: 'small' }}>This is required</span>
                )}
                <IonInput
                  {...register('lastName', { required: true })}
                  placeholder='Enter Last Name'
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>Relationship</IonLabel>
                <IonInput {...register('name')} placeholder='Enter Relationship'></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position='stacked'>Gender Pronoun*</IonLabel>
                {errors.genderId && errors.genderId.type === 'required' && (
                  <span style={{ color: 'red', fontSize: 'small' }}>This is required</span>
                )}
                <IonSelect
                  {...register('genderId', { required: true })}
                  value={watch('genderId')}
                  interface='action-sheet'
                  placeholder='Select Pronoun'
                >
                  {/* <SelectOptions options={genders} /> */}
                  {genders &&
                    genders.map((gender: Gender, index: number) => {
                      return (
                        <IonSelectOption key={index} value={gender.id}>
                          {gender.name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonCheckbox
                  checked={watch('dependent')}
                  onIonChange={(e) => {
                    console.log(e.detail.checked);
                    setValue('dependent', e.detail.checked);
                  }}
                  slot='start'
                />
                <IonLabel> &nbsp; Dependent</IonLabel>
              </IonItem>

              <IonItem>
                <IonCheckbox
                  checked={watch('child')}
                  onIonChange={(e) => {
                    console.log(e.detail.checked);
                    setValue('child', e.detail.checked);
                  }}
                  slot='start'
                />
                <IonLabel> &nbsp; Child</IonLabel>
              </IonItem>

              <IonItem>
                <IonLabel position='stacked'>Birthday*</IonLabel>
                <IonDatetimeButton
                  class='mw-input-lbl mw-full-width'
                  datetime='datetime'
                ></IonDatetimeButton>

                <IonModal keepContentsMounted={true} ref={modal}>
                  <IonDatetime
                    value={watch('birthdate')}
                    id='datetime'
                    placeholder=''
                    presentation='date'
                    color='primary'
                    className='mw-calender'
                  />
                </IonModal>
              </IonItem>

              <IonItem>
                <IonCheckbox
                  checked={watch('curateGift')}
                  onIonChange={(e) => {
                    console.log(e.detail.checked);
                    setValue('curateGift', e.detail.checked);
                  }}
                  slot='start'
                ></IonCheckbox>
                <IonLabel> &nbsp; Please curate gift for VIP</IonLabel>
              </IonItem>

              <IonItem>
                <IonLabel {...register('interests')} position='stacked'>
                  VIP Interests
                </IonLabel>
                <IonInput placeholder='Enter Text'></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position='stacked'>Other important information on VIP:</IonLabel>
                <IonTextarea {...register('anything')} placeholder='Enter text' />
              </IonItem>
            </IonList>
            <div style={{ padding: 16 }}>
              <IonButton type='submit' expand='block' color='dark'>
                Save
              </IonButton>
            </div>
            {id && (
              <div style={{ padding: 16 }}>
                <IonButton
                  onClick={() => {
                    if (id) {
                      VIPService.deleteVIP(id).then((res) => {
                        history.goBack();
                      });
                    }
                  }}
                  color={'dark'}
                  expand='block'
                  size='small'
                >
                  Delete
                </IonButton>
              </div>
            )}
            <div style={{ height: 100 }}></div>
          </div>{' '}
        </form>
      </IonContent>
    </IonPage>
  );
}
