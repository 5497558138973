import { IonContent, IonItem, IonLabel, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import { useEffect, useState } from 'react';

import HeadSubpage from '../Nav/HeaderSubpage';
import OrderService from '../Shared/services/OrderService';

export default function Memberships() {
  const [memberships, _memberships] = useState<any>([]);

  useEffect(() => {
    OrderService.GetMemberships().then((res) => {
      console.log('Membershisp', res.data);
      _memberships(res.data);
    });
  }, []);

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <div className='main-container leave-room-for-menu'>
          <div style={{ padding: 24 }}>
            <h3> Your Memberships</h3>
          </div>
          {memberships && memberships.length > 0 ? (
            <div className='memberships-container'>
              {memberships.map((m: any, index: number) => {
                return (
                  <div className='membership-item' key={index}>
                    <div className='membership-item-left'>
                      <IonItem>
                        <IonLabel>{m.partnerItem.name}</IonLabel>
                        <IonLabel slot='end'>
                          ${(parseFloat(m.partnerItem.price) * 0.01).toFixed(2)}
                        </IonLabel>
                      </IonItem>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='memberships-container'>
              <div style={{ textAlign: 'center' }} className='memberships-empty'>
                <h3> You have no memberships </h3>
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}
