import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

const petsService = {
  async getPet(id: number) {
    return axios.get(`${APIURL}/Pets/GetPet/${id}`);
  },
  async getPets() {
    return axios.get(`${APIURL}/Pets/GetPets`);
  },
  async getPetTypes() {
    return axios.get(`${APIURL}/PetTypes/GetPetTypes`);
  },
  async putPet(id: number, pet: any) {
    return axios.put(`${APIURL}/Pets/PutPet/${id}`, pet);
  },
  async postPet(pet: any) {
    return axios.post(`${APIURL}/Pets/PostPet`, pet);
  },
  async deletePet(id: number) {
    return axios.delete(`${APIURL}/Pets/DeletePet/${id}`);
  },
};

export default petsService;
