import { IonAlert, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { trash } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';

import VIPService from '../Shared/services/VIPService';
import '../Shared/theme/style.css';

interface Props {
  specoc?: any;
  index?: any;
  deleteBtn?: boolean;
  reload?: (e: any) => void;
}

export default function SpecialOccasionItem(props: Props) {
  const [showDelete, _showDelete] = useState(false);
  const history = useHistory();

  return (
    <div
      style={{
        border: '1px solid black',
        borderRight: '2px solid black',
        borderBottom: '3px solid black',
        margin: 16,
        padding: 5,
        borderRadius: 8,
        backgroundColor: 'white !important',
      }}
      key={props.index + '_' + Date.now() + '_' + props.specoc.name}
    >
      <IonLabel position='stacked'>{props.specoc.name}</IonLabel>
      <IonGrid className='ion-no-padding'>
        <IonRow className='ion-no-padding'>
          <IonCol className='ion-no-padding' size='10'>
            <div className=''>{props.specoc.datemmddyyyy}</div>
          </IonCol>
          {props.deleteBtn === true && (
            <>
              <IonCol
                onClick={() => {
                  _showDelete(true);
                }}
                style={{ textAlign: 'right' }}
              >
                <IonIcon style={{ fontSize: 24 }} color='primary' icon={trash} />
              </IonCol>
              <IonAlert
                isOpen={showDelete}
                onDidDismiss={() => _showDelete(false)}
                header='Watch Out!!! 👀'
                message='Are you sure you want to delete this?'
                buttons={[
                  {
                    text: 'Yep',
                    role: 'yep',
                    handler: () => {
                      VIPService.deleteSpecialOccasion(props.specoc.id).then(() => {
                        props.reload && props.reload(props.specoc.id);
                      });
                      props.reload && props.reload(props.index + '_' + Date.now());
                    },
                  },
                  {
                    text: 'Nope',
                    role: 'nope',
                  },
                ]}
              />
            </>
          )}{' '}
        </IonRow>
      </IonGrid>
    </div>
  );
}
