import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import {
  cardOutline,
  fileTrayFull,
  idCard,
  location,
  mail,
  paw,
  sparkles,
  wallet,
} from 'ionicons/icons';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export default function MenuList() {
  const history = useHistory();

  useEffect(() => {
    //console.log("member", member);
  }, []);

  return (
    <>
      <IonItem
        button
        onClick={() => {
          history.push('/maxcard');
        }}
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' icon={wallet} />
        <IonLabel>&nbsp; &nbsp;Budget</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() => {
          history.push('/user');
        }}
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' icon={idCard} />
        <IonLabel>&nbsp; &nbsp;About You</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() => {
          history.push('/addresses');
        }}
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' mode='md' icon={location} />
        <IonLabel>&nbsp; &nbsp;Addresses</IonLabel>
      </IonItem>
      <div className='lines'></div>
      <IonItem
        button
        onClick={() => {
          history.push('/dependents');
        }}
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' mode='md' icon={sparkles} />
        <IonLabel>&nbsp; &nbsp;Dependents & VIP</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() => {
          history.push('/pets');
        }}
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' mode='md' icon={paw} />
        <IonLabel>&nbsp; &nbsp;Pets</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() => {
          history.push('/memberships');
        }}
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' mode='md' icon={cardOutline} />
        <IonLabel>&nbsp; &nbsp;Memberships</IonLabel>
      </IonItem>
      <IonItem
        button
        onClick={() => {
          history.push('/past-activity');
        }}
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' mode='md' icon={fileTrayFull} />
        <IonLabel>&nbsp; &nbsp;Past Activity</IonLabel>
      </IonItem>

      <IonItem
        button
        target='_blank'
        href='mailto:support@maxwell.app'
        lines='none'
        className='menu-item'
      >
        <IonIcon color='primary' mode='md' icon={mail} />
        <IonLabel>&nbsp; &nbsp;Email Max</IonLabel>
      </IonItem>
    </>
  );
}
