import { IonButton, IonContent, IonLabel, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import ImageService from '../Shared/services/ImageService';
import MaxwellForm from '../Shared/services/MaxwellForm';
import partnerService from '../Shared/services/PartnerService';

export default function PartnerForm() {
  const [partner, _partner] = useState<any>();
  const [partnerItem, _partnerItem] = useState<any>();
  const [values, _values] = useState<any>();
  const history = useHistory();

  const { id } = useParams<any>();

  useEffect(() => {
    partnerService.getPartnerItem(id).then((res: any) => {
      //console.log(res.data);
      _partnerItem(res.data);
      partnerService.getPartner(res.data.partnerId).then((res2: any) => {
        //console.log(res2.data);
        _partner(res2.data);
        const vals = [];
        if (res && res.inputs) {
          const x = JSON.parse(res.inputs);
          for (const y of x) {
            vals.push({ [y.name]: y.type == 'checkbox' ? false : '' });
          }
        }

        if (res2 && res2.inputs) {
          const x = JSON.parse(res2.inputs);
          for (const y of x) {
            vals.push(y);
            for (const y of x) {
              vals.push({ [y.name]: y.type == 'checkbox' ? false : '' });
            }
          }
        }

        _values(vals);
      });
    });
  }, []);

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== '', //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  function Inputs() {
    const inputs = [];
    if (partnerItem && partnerItem.inputs) {
      const x = JSON.parse(partnerItem.inputs);
      for (const y of x) {
        inputs.push(y);
      }
    }

    if (partner && partner.inputs) {
      const x = JSON.parse(partner.inputs);
      for (const y of x) {
        inputs.push(y);
      }
    }

    return inputs.map((input: any, index: number) => {
      if (input.type === 'select') {
        return (
          <MaxwellForm.Select
            key={index}
            label={input.label}
            options={input.options}
            value={values ? values[input.name] : ''}
            required={input.required}
            placeholder={input.placeholder}
            validation={(data: any) => {
              HandleInputErrors(data);
            }}
            onChange={(value: string) => {
              _values({ ...values, [input.name]: value });
            }}
          />
        );
      } else if (input.type === 'date') {
        return (
          <MaxwellForm.DateTime
            key={index}
            label={input.label}
            value={values ? values[input.name] : ''}
            required={input.required}
            placeholder={input.placeholder}
            validation={(data: any) => {
              HandleInputErrors(data);
            }}
            onChange={(value: string) => {
              _values({ ...values, [input.name]: value });
            }}
          />
        );
      } else if (input.type === 'checkbox') {
        return (
          <MaxwellForm.Checkbox
            key={index}
            label={input.label}
            value={values ? values[input.name] : ''}
            required={input.required}
            validation={(data: any) => {
              HandleInputErrors(data);
            }}
            onChange={(value: string) => {
              _values({ ...values, [input.name]: value });
            }}
          />
        );
      } else if (input.type === 'paragraph') {
        return (
          <div
            key={index}
            style={{
              fontSize: input['font-size'] ? input['font-size'] : 'normal',
              fontWeight: input['font-weight'] ? input['font-weight'] : 'normal',
              color: input['font-color'] ? input['font-color'] : 'black',
              textAlign: input['text-align'] ? input['text-align'] : 'left',
              padding: input['padding'] ? input['padding'] : '0px',
              margin: input['margin'] ? input['margin'] : '0px',
            }}
          >
            {input.body && <IonLabel>{input.body}</IonLabel>}
          </div>
        );
      } else if (input.type === 'image') {
        return (
          <div
            key={index}
            style={{
              width: input['width'] ? input['width'] : '100%',
              height: input['height'] ? input['height'] : '100%',
              border: input['border'] ? input['border'] : '1px solid black',
              borderRadius: input['border-radius'] ? input['border-radius'] : '10px',
              textAlign: input['text-align'] ? input['text-align'] : 'left',
              padding: input['padding'] ? input['padding'] : '0px',
              margin: input['margin'] ? input['margin'] : '0px',
            }}
          >
            {input.url && (
              <img
                style={{
                  width: input['width'] ? input['width'] : '100%',
                  height: input['height'] ? input['height'] : '100%',
                }}
                src={input.url ? ImageService.Url(input.url) : ''}
              />
            )}
          </div>
        );
      } else {
        return (
          <MaxwellForm.Input
            key={index}
            label={input.label}
            value={values ? values[input.name] : ''}
            required={input.required}
            placeholder={input.placeholder}
            validation={(data: any) => {
              HandleInputErrors(data);
            }}
            onChange={(value: string) => {
              _values({ ...values, [input.name]: value });
            }}
          />
        );
      }
    });
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <div className='main-container'>
          <div style={{ padding: 24, textAlign: 'center' }}></div>
          <div style={{ padding: 24, marginTop: -24 }}> {Inputs()}</div>
          <div>
            <IonButton
              color={'dark'}
              expand='block'
              onClick={() => {
                if (formErrors.length === 0) {
                  localStorage.setItem('cart-details', JSON.stringify(values));
                  //console.log(values);
                  history.push('/checkout');
                }
              }}
            >
              Continue
            </IonButton>
            {formErrors.length > 0 && (
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <IonLabel color='danger'>
                  {formErrors.map((x: any, index: number) => (
                    <div key={index}>{x}</div>
                  ))}
                </IonLabel>
              </div>
            )}
            <div style={{ marginTop: 40 }}>
              <IonButton
                color={'light'}
                size='small'
                expand='block'
                onClick={() => {
                  history.push('/partner/' + partner.id);
                }}
              >
                Cancel
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
