import { IonContent, IonList, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import MemberService from '../Shared/services/MemberService';
import { Member } from '../Shared/types/member';
import { Regalos } from '../Shared/types/regalos';

import MembersItem from './MembersItem';

export default function MembersPage() {
  const history = useHistory();
  const { id } = useParams<any>();
  const [search, _search] = useState<string>('');
  const [members, _members] = useState<Array<Member>>();
  const [regalos, _regalos] = useState<Array<Regalos>>();

  useEffect(() => {
    MemberService.getMembers([0]).then((res: any) => {
      //console.log(res.data);
      _members(res.data);
    });
  }, []);

  function List() {
    return (
      members &&
      members
        .filter((x) => {
          if (search === '') {
            return true;
          }
          if (x.preferredName && x.preferredName.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            return true;
          } else if (x.firstName && x.firstName.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            return true;
          }
          return false;
        })
        .map((member: Member, index: number) => {
          if (member.preferredName && member.firstName) {
            return <MembersItem key={index} member={member} link={'/member/' + member.id} />;
          } else {
            return null;
          }
        })
    );
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <div className='main-container leave-room-for-menu ion-padding'>
          <div style={{ textAlign: 'left' }}>
            <h3>Members</h3>
          </div>
          <input
            style={{
              width: '100%',
              padding: 10,
              marginBottom: 20,
              borderRadius: 5,
              border: '2px solid black',
            }}
            placeholder='Search'
            onChange={(e) => {
              _search(e.target.value);
            }}
          ></input>
          <div>
            <IonList>{members && List()}</IonList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
