import { Employer } from '../types/employer';

export function features(employer: Employer) {
  if (employer && employer.hasBenefits == false) {
    // set classname BenefitsTab to hide tab
    //console.log("hide marketplace tab");
    document.getElementsByClassName('BenefitsTab')[0].classList.add('hide');
    localStorage.setItem('hideBenefitsTab', 'true');
  }

  if (employer && employer.hasBenefits == true) {
    // set classname BenefitsTab to hide tab
    //console.log("show Benefits tab");
    document.getElementsByClassName('BenefitsTab')[0].classList.remove('hide');
    localStorage.setItem('hideBenefitsTab', 'false');
  }

  if (employer && employer.hasMarketplace == false) {
    // set classname MarketplaceTab to hide tab
    //console.log("hide marketplace tab");
    document.getElementsByClassName('MarketplaceTab')[0].classList.add('hide');
    localStorage.setItem('hideMarketplaceTab', 'true');
  }

  if (employer && employer.hasMarketplace == true) {
    // set classname MarketplaceTab to hide tab
    //console.log("show marketplace tab");
    document.getElementsByClassName('MarketplaceTab')[0].classList.remove('hide');
    localStorage.setItem('hideMarketplaceTab', 'false');
  }

  if (employer && employer.hasRegalos == false) {
    // set classname MarketplaceTab to hide tab
    //console.log("hide marketplace tab");
    document.getElementsByClassName('RegalosTab')[0].classList.add('hide');
    localStorage.setItem('hideRegalosTab', 'true');
  }

  if (employer && employer.hasRegalos == true) {
    // set classname MarketplaceTab to hide tab
    //console.log("show marketplace tab");
    document.getElementsByClassName('RegalosTab')[0].classList.remove('hide');
    localStorage.setItem('hideRegalosTab', 'false');
  }
}
