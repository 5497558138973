import _ from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import '../Shared/theme/style.css';

import Skeleton from 'react-loading-skeleton';
import { useRecoilState } from 'recoil';

import Card from '../Shared/Card';
import announvementService, { announcementsState } from '../Shared/services/AnnouncementService';
import { darkmodeState } from '../Shared/services/MemberService';

export default function HomeAnnouncements() {
  const history = useHistory();
  const [dark, _dark] = useRecoilState(darkmodeState);

  const [announcements, _announcements] = useRecoilState<any>(announcementsState);

  useEffect(() => {
    // call api or anything
    announvementService.getAnnouncements().then((res) => {
      _announcements(res.data);
    });
  }, []);

  function AnnouncementList(announcements: any) {
    if (announcements !== null) {
      const actdata = _.orderBy(announcements, ['date', 'dsc']);
      return actdata.map((announcement: any, index: number) => {
        return (
          <Card
            key={index}
            cover={true}
            height={160}
            width={160}
            id={announcement.id}
            title={announcement.title}
            picture={announcement.picture}
            clicked={() => {
              history.push('/announcement/' + announcement.id);
            }}
          />
        );
      });
    }
  }

  return (
    <div className='' style={{ zIndex: 1, backgroundColor: '#fcfcfc' }}>
      <div
        className='announcements-header padding'
        style={{
          marginTop: dark ? -30 : 0,
          zIndex: -1,
          paddingLeft: 22,
          fontWeight: 500,
        }}
      >
        {announcements && announcements.length != 0 ? (
          <span className='rubik'>{!dark && <> Announcements</>}</span>
        ) : (
          <Skeleton
            width={140}
            height={dark ? 0 : 20}
            baseColor='#323232'
            highlightColor='#191919'
          />
        )}

        {/* <div className="sub-label" style={{ fontWeight: 400 }}>
          {announcements &&
            announcements.updatedDatemmddyyyy &&
            "Thru: " +
              _.orderBy(announcements, ["date", "dsc"]).slice(0, 1)[0]
                .updatedDatemmddyyyy}
          {announcements &&
            announcements.length == 0 &&
            " no announcements yet"}
        </div> */}
      </div>
      <div className='announcement-cards '>
        <div className=' hide-scrollbar' style={{ width: '100vw' }}>
          <div className='horizontal-container  hide-scrollbar'>
            {(announcements && <>{AnnouncementList(announcements)}</>) || (
              <>
                <Skeleton
                  style={{ marginLeft: 10, borderRadius: 10 }}
                  height={160}
                  width={160}
                  inline={true}
                />
              </>
            )}
            {announcements && announcements.length === 0 && (
              <Skeleton
                style={{ borderRadius: 10 }}
                height={160}
                width={160}
                enableAnimation={false}
                inline={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
