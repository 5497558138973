import axios from 'axios';
import { atom } from 'recoil';

const APIURL = process.env.REACT_APP_API_HOST;

export const featureFlagState = atom({
  key: 'featureFlagState', // unique ID (with respect to other atoms/selectors)
  default: null,
});

const Util = {
  async featureFlags() {
    return axios.get(`${APIURL}/Util/FeatureFlags`).then((response) => {
      // console.log("Features", response.data);
      localStorage.setItem('FeatureFlags', JSON.stringify(response.data));
      return response.data;
    });
  },
  //TODO - CLEAN - Good lord this is horrible and relies on index of FeatureFlags being added. Use Name instead. See Hrv.FeatureFlagService instead
  FeatureAvailable(id: number, flags?: any) {
    let features = localStorage.getItem('FeatureFlags');
    if (flags) {
      features = JSON.stringify(flags);
    }
    if (features) {
      const parsedFeatures = JSON.parse(features);
      for (const f of parsedFeatures) {
        if (f.id == id) {
          if (f.beta == true) {
            if (localStorage.getItem('showBeta') === 'true') {
              return true;
            }
          } else {
            return f.show;
          }
        }
      }
    }
    return false;
  },
};

export default Util;
