import _ from 'lodash';
import { Component } from 'react';

import OnboardingService from '../Shared/services/OnboardingService';
import PetsService from '../Shared/services/PetService';

import OBIntro from './OB00_Intro';
import OBStayInTouch from './OB01_StayInTouch';
import OBTheBasics from './OB02_TheBasics';
import OBAddress from './OB03_Address';
import OBPicture from './OB04_Picture';
import OBEngLevels from './OB05_EngLevels';
import OBAdvantages from './OB06_Advantages';
import OBKnowYou from './OB07_KnowYou';
import OBThankYou from './OB08_ThankYou';
import OBWorkAddress from './OB09_WorkAddress';
import OBAddPet from './OB10_AddPet';
import OBPets from './OB10_Pets';
import OBAddVip from './OB11_AddVip';
import OBVips from './OB11_Vips';
import OBAnything from './OB12_Anything';
import OBSuccess from './OB13_Success';

export default class Onboarding extends Component {
  state = {
    step: 0,
    memberid: 1,
    s1_MobileNumber: 0,
    s2_FirstName: '',
    s2_LastName: '',
    s2_PreferredName: '',
    s2_GenderPronounId: '',
    s2_LocationId: 0,
    s3_ProfileImageFileUploadId: 0,
    s4_EnergyLevel: 0,
    s4_ProductivityLevel: 0,
    s4_DimEnergyLevel: '',
    s4_TimeGain1: '',
    s4_TimeGain2: '',
    s4_TimeGain3: '',
    s5_ExistingBenefits: [],
    s6_Birthday: '',
    s6_RaceEthnicityId: '',
    s6_MaritalStatusId: '',
    s6_CaregiverStatusId: '',
    s7_AddressLine1: '',
    s7_AddressLine2: '',
    s7_City: '',
    s7_State: '',
    s7_ZipCode: '',
    s9_WorkAddressLine1: '',
    s9_WorkAddressLine2: '',
    s9_WorkCity: '',
    s9_WorkState: '',
    s9_WorkZipCode: '',
    s10_Pets: [],
    s11_VIPs: [],
    s12_OtherNotes: '',
    s1099_EditPet: [],
    s1199_EditVIP: [],
    genders: [{ label: '', value: '' }],
    relationships: [{ label: '', value: '' }],
    pettypes: [{ label: '', value: '' }],
    races: [{ label: '', value: '' }],
    maritals: [{ label: '', value: '' }],
    caregivers: [{ label: '', value: '' }],
    locations: [{ label: '', value: '' }],
    existingbenefits: [{ label: '', value: '' }],
    selectedFile: null,
  };

  // go back to previous step
  prevStep = (id?: number) => {
    const { step } = this.state;
    if (id) {
      this.setState({ step: id });
    } else {
      this.setState({ step: step - 1 });
    }
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    if (step > 0) {
      OnboardingService.post(this.state).catch((err) => {
        console.log(err);
        console.log(err.response);
      });
    }

    this.setState({ step: step + 1 });
  };

  // proceed to the selected step
  gotoStep = (goStep: number) => {
    this.setState({ step: goStep });
  };

  // handle field change
  handleChange = (input: any, value: any, numonly?: boolean) => {
    if (numonly === true) {
      const numValue = Number(String(value).replace(/\D/gm, ''));
      this.setState({ [input]: numValue });
    } else {
      this.setState({ [input]: value });
    }
    // Send State to Database
  };

  loadState = () => {
    // Get State from Database
    if (this.state.step === 0) {
      OnboardingService.get().then((res) => {
        this.setState(res.data);
      });

      OnboardingService.getCareGivers().then((res) => {
        const resdata = _.orderBy(res.data, ['name', 'asc']);
        this.setState({ caregivers: resdata });
      });

      OnboardingService.getExistingBenefits().then((res) => {
        const resdata = _.orderBy(res.data, ['name', 'asc']);
        this.setState({ existingbenefits: resdata });
      });

      OnboardingService.getGenders().then((res) => {
        const resdata = _.orderBy(res.data, ['name', 'asc']);
        this.setState({ genders: resdata });
      });

      OnboardingService.getLocations().then((res) => {
        const resdata = _.orderBy(res.data, ['name', 'asc']);
        this.setState({ locations: resdata });
      });

      OnboardingService.getMaritals().then((res) => {
        const resdata = _.orderBy(res.data, ['name', 'asc']);
        this.setState({ maritals: resdata });
      });

      PetsService.getPetTypes().then((res) => {
        const resdata = _.orderBy(res.data, ['name', 'asc']);
        this.setState({ pettypes: resdata });
      });

      OnboardingService.getRaces().then((res) => {
        const resdata = _.orderBy(res.data, ['name', 'asc']);
        this.setState({ races: resdata });
      });
    }
  };

  // set edit pet state
  editState = (state: any, value: any) => {
    this.setState({ [state]: value });
  };

  componentDidMount() {
    this.loadState();
  }

  render() {
    const { step } = this.state;
    const {
      memberid,
      s1_MobileNumber,
      s2_FirstName,
      s2_LastName,
      s2_PreferredName,
      s2_GenderPronounId,
      s2_LocationId,
      s3_ProfileImageFileUploadId,
      s4_ProductivityLevel,
      s4_EnergyLevel,
      s4_DimEnergyLevel,
      s4_TimeGain1,
      s4_TimeGain2,
      s4_TimeGain3,
      s5_ExistingBenefits,
      s6_Birthday,
      s6_RaceEthnicityId,
      s6_MaritalStatusId,
      s6_CaregiverStatusId,
      s7_AddressLine1,
      s7_AddressLine2,
      s7_City,
      s7_State,
      s7_ZipCode,
      s9_WorkAddressLine1,
      s9_WorkAddressLine2,
      s9_WorkCity,
      s9_WorkState,
      s9_WorkZipCode,
      s10_Pets,
      s11_VIPs,
      s12_OtherNotes,
      s1099_EditPet,
      s1199_EditVIP,
      genders,
      relationships,
      pettypes,
      races,
      maritals,
      caregivers,
      locations,
      existingbenefits,
      selectedFile,
    } = this.state;
    const values = {
      memberid,
      s1_MobileNumber,
      s2_FirstName,
      s2_LastName,
      s2_PreferredName,
      s2_GenderPronounId,
      s2_LocationId,
      s3_ProfileImageFileUploadId,
      s4_ProductivityLevel,
      s4_EnergyLevel,
      s4_DimEnergyLevel,
      s4_TimeGain1,
      s4_TimeGain2,
      s4_TimeGain3,
      s5_ExistingBenefits,
      s6_Birthday,
      s6_RaceEthnicityId,
      s6_MaritalStatusId,
      s6_CaregiverStatusId,
      s7_AddressLine1,
      s7_AddressLine2,
      s7_City,
      s7_State,
      s7_ZipCode,
      s9_WorkAddressLine1,
      s9_WorkAddressLine2,
      s9_WorkCity,
      s9_WorkState,
      s9_WorkZipCode,
      s10_Pets,
      s11_VIPs,
      s12_OtherNotes,
      s1099_EditPet,
      s1199_EditVIP,
      genders,
      relationships,
      pettypes,
      races,
      maritals,
      caregivers,
      locations,
      existingbenefits,
      selectedFile,
    };

    switch (step) {
      case 0:
        return <OBIntro nextStep={this.nextStep} />;
      case 1:
        return (
          <OBStayInTouch
            nextStep={this.nextStep}
            handleChange={(input: any, e: any, numonly?: boolean) =>
              this.handleChange(input, e, numonly)
            }
            values={values}
          />
        );
      case 2:
        return (
          <OBTheBasics
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      case 3:
        return (
          <OBAddress
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      case 4:
        return <OBPicture prevStep={this.prevStep} nextStep={this.nextStep} values={values} />;
      case 5:
        return (
          <OBEngLevels
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      case 6:
        return (
          <OBAdvantages
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      case 7:
        return (
          <OBKnowYou
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );

      case 8:
        return (
          <OBThankYou
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            // handleChange={(input:any, e:any)=>this.handleChange(input, e)}
            values={values}
          />
        );
      case 33:
        return (
          <OBWorkAddress
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      case 9:
        return (
          <OBPets
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            gotoStep={(goStep: number) => this.gotoStep(goStep)}
            editPet={(state: any, value: any) => this.editState(state, value)}
            values={values}
          />
        );
      case 10999:
        return (
          <OBAddPet
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            gotoStep={(goStep: number) => this.gotoStep(goStep)}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      case 10:
        return (
          <OBVips
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            gotoStep={(goStep: number) => this.gotoStep(goStep)}
            editVIP={(state: any, value: any) => this.editState(state, value)}
            values={values}
          />
        );
      case 11999:
        return (
          <OBAddVip
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            gotoStep={(goStep: number) => this.gotoStep(goStep)}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      //OBVipSkip
      case 11:
        return (
          <OBAnything
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={(input: any, e: any) => this.handleChange(input, e)}
            values={values}
          />
        );
      case 12:
        return <OBSuccess />;
      default:
      // do nothing
    }
  }
}
