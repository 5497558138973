import { IonCol, IonGrid, IonRow, IonSlide, IonSlides } from '@ionic/react';

import '../Shared/theme/style.css';

import _ from 'lodash';
import { useState, useEffect, RefObject, useMemo, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import { balanceVisibleState } from '../Shared/services/ActivityService';
import { employerState } from '../Shared/services/EmployerService';
import ImageService from '../Shared/services/ImageService';
import isMobile from '../Shared/services/IsMobile';
import { darkmodeState, memberState } from '../Shared/services/MemberService';

export default function HomeBalance() {
  const history = useHistory();
  const [dark, _dark] = useRecoilState(darkmodeState);
  const [member, _member] = useRecoilState<any>(memberState);
  const [employer, _employer] = useRecoilState<any>(employerState);

  const [balanceVisible, _balanceVisible] = useRecoilState<any>(balanceVisibleState);

  const [balance, _balance] = useState(member.balance);

  function useOnScreen(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
      () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
      [ref],
    );

    useEffect(() => {
      if (ref.current == null) return;
      observer.observe(ref.current);
      return () => observer.disconnect();
    }, []);

    return isIntersecting;
  }

  const ref = useRef<HTMLDivElement>(null);
  // _balanceVisible(useOnScreen(ref));

  function TheBigNumber(numProps: { total: any; title: string }) {
    function pad(d: any) {
      return d == '00' ? '00' : d < 10 ? '0' + d.toString() : d.toString();
    }

    let total = numProps.total;
    if (numProps.total < 0) {
      total = 0;
    }

    return (
      <>
        <div
          className='big-number-font'
          style={{
            display: 'inline-block',
            fontSize: 65,
            fontWeight: 100,
            verticalAlign: 'top',
            paddingTop: 10,
            paddingRight: 2,
          }}
        >
          $
        </div>
        <div
          className='big-number-font'
          style={{
            display: 'inline-block',
            fontSize: 80,
            fontWeight: 300,
            verticalAlign: 'top',
          }}
        >
          {_.split((total / 100).toString(), '.')[0]}
        </div>

        <div
          style={{
            display: 'inline-block',
            fontSize: 50,
            verticalAlign: 'top',
            paddingTop: 14,
          }}
        >
          <div
            className='big-number-font'
            style={{
              fontSize: 40,
              fontWeight: 400,
            }}
          >
            .
            {total != 0
              ? total < 10
                ? '0' + Math.round(total).toString().slice(-2)
                : Math.round(total).toString().slice(-2)
              : '00'}
          </div>
          <div
            style={{
              textAlign: 'left',
              fontSize: 12,
              paddingLeft: 2,
              marginTop: -4,
              verticalAlign: 'top',
              letterSpacing: 1,
              textTransform: 'uppercase',
              marginBottom: 40,
              color: dark ? 'white' : 'black',
            }}
          >
            {numProps.title}
          </div>
        </div>
        {numProps.total < 0 && (
          <div
            style={{
              border: '2px solid burlywood',
              borderRadius: 10,
              fontSize: 12,
              padding: 4,
              color: 'black',
              fontWeight: 500,
              width: 'calc(100% - 48px)',
              position: 'absolute',
              bottom: 10,
              left: 0,
              marginLeft: 24,
              display: 'block',
              backgroundColor: 'rgba(222,184,135,.9)',
            }}
          >
            Oops! You may receive less than usual on your next top-up. This is likely because there
            was an overdraft. Gas pumps are often the cause; they may require you to stop at your
            available balance manually.{' '}
          </div>
        )}
      </>
    );
  }

  return (
    <div className=' padding' style={{ backgroundColor: dark ? '#1c1c1c' : '' }}>
      <IonGrid>
        <IonRow>
          <IonCol style={{ height: 120 }} className='mw-t2'>
            {(member && member.balance != null && (
              <div
                ref={ref}
                style={{
                  color: dark ? 'white' : 'rgba(94, 122, 114, 1)',
                  textAlign: isMobile() ? 'center' : 'left',
                  textShadow: dark ? '1px 1px 3px rgba(94, 122, 114, 1)' : '',
                }}
              >
                {isMobile() ? (
                  <IonSlides
                    pager={true}
                    options={{}}
                    style={{
                      marginLeft: -24,
                      marginRight: -24,
                      paddingBottom: 16,
                    }}
                  >
                    <IonSlide
                      onClick={() => {
                        history.push('/maxcard');
                      }}
                    >
                      <TheBigNumber total={member.balance} title='CARD' />
                    </IonSlide>
                    <IonSlide
                      onClick={() => {
                        history.push('/maxcard/regalos');
                      }}
                    >
                      <TheBigNumber total={member.regalosBalance} title='REGALOS' />
                    </IonSlide>
                    {/* <IonSlide
                      onClick={() => {
                        history.push("/maxcard");
                      }}
                    >
                      <TheBigNumber
                        total={member.balance + member.regalosBalance}
                        title="COMBINED"
                      />
                    </IonSlide> */}
                  </IonSlides>
                ) : (
                  <div
                    onClick={() => {
                      history.push('/maxcard');
                    }}
                  >
                    <TheBigNumber total={member.balance} title='CARD' />
                  </div>
                )}
              </div>
            )) || <Skeleton width={160} height={110} />}
          </IonCol>
          {!isMobile() && employer && (
            <IonCol>
              <>
                <div
                  style={{
                    backgroundImage: 'url(' + ImageService.Url(employer.logo) + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                    height: '100%',
                    borderRadius: 10,
                    textAlign: 'center',
                    marginLeft: 'calc(50% - 70px)',
                    border: '0px solid black',
                  }}
                ></div>
              </>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
}
