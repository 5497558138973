import { IonContent, IonList, IonMenu, IonMenuToggle } from '@ionic/react';
import { useRecoilState } from 'recoil';

import { memberState } from '../Shared/services/MemberService';

import MenuFooter from './MenuFooter';
import MenuHeader from './MenuHeader';
import MenuList from './MenuList';

function Menu() {
  const [member, _member] = useRecoilState<any>(memberState);

  return (
    <>
      <IonMenu side='start' contentId='main' menuId='menu' swipeGesture={false}>
        <IonContent>
          <IonMenuToggle autoHide={false}>
            <MenuHeader />
          </IonMenuToggle>
          <IonList>
            <IonMenuToggle autoHide={false}>
              <MenuList />
            </IonMenuToggle>
          </IonList>
          <IonMenuToggle autoHide={true}>
            <MenuFooter />
          </IonMenuToggle>
        </IonContent>
      </IonMenu>
    </>
  );
}
export default Menu;
