import { IonButton, IonContent, IonList, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';

import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import MaxwellForm from '../Shared/services/MaxwellForm';
import petsService from '../Shared/services/PetService';

type Inputs = {
  name: string;
  weight: string;
  petTypeId: number;
};

export default function PetPage() {
  const [petTypes, _petTypes] = useState<any[]>([]);
  const [name, _name] = useState<string>('');
  const [weight, _weight] = useState<string>('');
  const [petTypeId, _petTypeId] = useState<any>(0);

  const { handleSubmit } = useForm<Inputs>();

  const history = useHistory();
  const { id } = useParams<any>();

  const onSubmit: SubmitHandler<Inputs> = () => {
    const pet = {
      id: id,
      name: name,
      weight: weight,
      petTypeId: petTypeId,
    };
    if (id) {
      petsService.putPet(id, pet).then(() => {
        history.goBack();
      });
    } else {
      petsService.postPet(pet).then(() => {
        history.goBack();
      });
    }
  };

  useEffect(() => {
    petsService.getPetTypes().then((res) => {
      _petTypes(res.data);
    });
    if (id) {
      petsService.getPet(id).then((res) => {
        _name(res.data.name);
        _weight(res.data.weight);
        _petTypeId(res.data.petTypeId);
      });
    }
  }, [id]);

  const [formErrors, _formErrors] = useState<any>([]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== '', //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <div className='main-container leave-room-for-menu'>
          <div style={{ paddingLeft: 16, paddingTop: 24 }}></div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList style={{ paddingLeft: 24, paddingRight: 24 }}>
              <MaxwellForm.Input
                label='Name'
                required={true}
                placeholder='Enter Name (i.e. Max)'
                value={name}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _name(value);
                }}
              />

              <MaxwellForm.Input
                label='Weight'
                required={true}
                placeholder='Enter Weight 10 lbs'
                value={weight}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _weight(value);
                }}
              />
              <MaxwellForm.Select
                label='Type'
                required={true}
                options={petTypes}
                value={petTypeId}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  console.log(value);
                  _petTypeId(value);
                }}
              />
            </IonList>

            <div style={{ padding: 16 }}>
              <IonButton type='submit' expand='block' color='dark'>
                Save Pet
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
}
