import axios from 'axios';
import { atom } from 'recoil';

const APIURL = process.env.REACT_APP_API_HOST;

export const announcementsState = atom({
  key: 'announcementsState', // unique ID (with respect to other atoms/selectors)
  default: [],
});

const announvementService = {
  async getAnnouncements() {
    return axios.get(`${APIURL}/Announcements/GetAnnouncements`);
  },
  async getAnnouncement(id: number) {
    return axios.get(`${APIURL}/Announcements/GetAnnouncement/${id}`);
  },
  async putAnnouncement(id: number, annoucement: any) {
    return axios.put(`${APIURL}/Announcements/PutAnnouncement/${id}`, annoucement);
  },
  async AnnouncementSeen(id: number) {
    return axios.post(`${APIURL}/Announcements/PostAnnouncementSeen/${id}`);
  },
  async postAnnouncement(announcement: any) {
    return axios.post(`${APIURL}/Announcements/PostAnnouncement`, announcement);
  },
  async deleteAnnouncement(id: number) {
    return axios.delete(`${APIURL}/Announcements/DeleteAnnouncement/${id}`);
  },
};

export default announvementService;
