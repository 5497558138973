import axios from 'axios';
import moment from 'moment';
import { atom } from 'recoil';

const APIURL = process.env.REACT_APP_API_HOST;

export const activityState = atom({
  key: 'activityState', // unique ID (with respect to other atoms/selectors)
  default: [],
});

// showBackState
export const showBackState = atom({
  key: 'showBackState', // unique ID (with respect to other atoms/selectors)
  default: false,
});

//balanceVisibleState
export const balanceVisibleState = atom({
  key: 'balanceVisibleState', // unique ID (with respect to other atoms/selectors)
  default: false,
});

export function GetActivityId(activity: any) {
  const id = activity && activity.incrementalBalanceId ? activity.incrementalBalanceId : 0;
  return activity ? moment(activity.date).format('YY') + id.toString().padStart(4, '0') : undefined;
}

const ActivityService = {
  async getActivities(page?: number) {
    return axios.get(`${APIURL}/Activities/GetActivities` + (page ? `?page=${page}` : ''));
  },
  async getMerchantActivities(id: number) {
    if (!id) return Promise.resolve({ data: [] });
    return axios.get(`${APIURL}/Activities/GetMerchantActivities/` + id);
  },
  async getActivity(id: number) {
    return axios.get(`${APIURL}/Activities/GetActivity/${id}`);
  },
  async putHeartsActivity(id: number, hearts: number) {
    return axios.put(`${APIURL}/Activities/PutHeartsActivity/${id}`, {
      hearts,
    });
  },
};

export default ActivityService;
