import {
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
} from '@ionic/react';

import '../Shared/theme/style.css';

import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import ActivityService, { GetActivityId } from '../Shared/services/ActivityService';

export default function PastActivity() {
  const history = useHistory();
  const [activity, _activity] = useState<any>();
  const [activities, _activities] = useState<any>();

  useEffect(() => {
    ActivityService.getActivities().then((res) => {
      _activities(res.data);
      ActivityService.getActivity(1).then((res) => {
        // merge with activities
        const act = activities;
        act.concat(res.data);
        _activities(act);
        ActivityService.getActivity(2).then((res) => {
          // merge with activities
          const act2 = activities;
          act2.concat(res.data);
          _activities(act2);
        });
      });
    });
  }, []);

  function ActivityList(activities: any) {
    if (activities !== null) {
      const actdata = _.orderBy(
        activities,
        (x) => {
          return GetActivityId(x);
        },
        ['desc'],
      );
      return actdata.map((activity: any, index: number) => {
        if (activity && activity.title) {
          return (
            <IonItem
              onClick={() => {
                history.push('/related-activity/' + activity.id);
              }}
              key={index}
              style={{ paddingTop: 10 }}
            >
              <IonGrid className='ion-no-padding'>
                {' '}
                <IonRow style={{ fontSize: 'small' }}>
                  <IonCol>
                    <IonLabel>
                      ID:&nbsp;
                      {GetActivityId(activity)}
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow style={{ fontWeight: 500 }}>
                  <IonCol>
                    <IonLabel>{activity.title}</IonLabel>
                  </IonCol>
                  <IonCol style={{ textAlign: 'right' }}>
                    ${(Math.round(activity.amount) / 100).toFixed(2)}
                  </IonCol>
                </IonRow>
                <IonRow style={{ fontSize: 'small' }}>
                  <IonCol>
                    <IonLabel>{moment(activity.date).format('lll')}</IonLabel>
                  </IonCol>
                  <IonCol style={{ textAlign: 'right', fontSize: 12 }}>
                    {/* <IonRow>
                      <IonCol>
                        <span style={{ fontSize: 8 }}>Budget</span> $
                        {(Math.round(activity.balance) / 100).toFixed(2)}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <span style={{ fontSize: 8 }}>Regalos</span> $
                        {(Math.round(activity.regalosBalance) / 100).toFixed(2)}
                      </IonCol>
                    </IonRow> */}
                  </IonCol>
                </IonRow>
                {activity.activityStatus.complete != true && (
                  <IonRow
                    style={{
                      fontSize: 'small',
                      color: 'var(--ion-color-danger)',
                    }}
                  >
                    <IonCol style={{ textAlign: 'right' }}>
                      <IonLabel>{activity.activityStatus.name}</IonLabel>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol
                    style={{
                      fontSize: 'small',
                      paddingTop: 5,
                      paddingBottom: 2,
                    }}
                  >
                    <b>Detail:</b> {activity.activityType.name}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          );
        }
      });
    }
  }

  function TagList(activity: any) {
    if (activity && activity.activityTags && activity.activityTags[0]) {
      return activity.activityTags.map((at: any, index: number) => {
        return <IonChip key={index}>{at.tag.name}</IonChip>;
      });
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        <div className='main-container leave-room-for-menu'>
          <br />
          <IonList>
            <IonItemDivider>Past Activity</IonItemDivider>

            {activities && ActivityList(activities)}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
}
