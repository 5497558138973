import { useRecoilState } from 'recoil';

import { employerState } from '../Shared/services/EmployerService';
import ImageService from '../Shared/services/ImageService';

export default function MenuHeader() {
  const [employer, _employer] = useRecoilState<any>(employerState);

  return (
    <>
      <div style={{ textAlign: 'center', paddingTop: 30 }}>
        {employer && (
          <>
            <div
              style={{
                backgroundImage: 'url(' + ImageService.Url(employer.logo) + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: 140,
                height: 140,
                borderRadius: 10,
                textAlign: 'center',
                marginLeft: 'calc(50% - 70px)',
                border: '0px solid black',
              }}
            ></div>
          </>
        )}
      </div>
    </>
  );
}
