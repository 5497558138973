import {
  IonButton,
  IonContent,
  IonHeader,
  IonModal,
  IonSlide,
  IonSlides,
  IonSpinner,
  useIonAlert,
} from '@ionic/react';
import JSConfetti from 'js-confetti';
import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';

import '../Shared/theme/style.css';

import { useHistory } from 'react-router';

import successLottie from '../Shared/lottie/63617-credit-card-flip-black.json';
import MaxwellForm from '../Shared/services/MaxwellForm';
import memberService from '../Shared/services/MemberService';
import { CardHolder } from '../Shared/types/cardHolder';

import CardActivity from './CardActivity';
import SlideOrderCard from './SlideOrderCard';
import SlidePhysicalCard from './SlidePhysicalCard';
import SlideRegalosCard from './SlideRegalosCard';
import SlideVirtualCard from './SlideVirtualCard';

interface Props {
  width?: any;
  height?: any;
  showFavorite?: boolean;
  favorite?: boolean;
  cardholder: CardHolder;
  member: any;
  segment: string;
  slideChanged: (e: any) => void;
}

export default function MaxwellCard(props: Props) {
  const [elKey, _elKey] = useState();

  const [showCodeModal, _showCodeModal] = useState(false);
  const [code, _code] = useState<any>();
  const [codeError, _codeError] = useState<any>();
  const [checkingCode, _checkingCode] = useState(false);
  const [cards, _cards] = useState<any>();
  const [physicalCard, _physicalCard] = useState<any>();
  const [virtualCard, _virtualCard] = useState<any>();
  const [precommited, _precommited] = useState<any>();
  const [hasPhysicalCard, _hasPhysicalCard] = useState(false);
  const [currentSlideIndex, _currentSlideIndex] = useState(0);
  const [showReplaceCardModal, _showReplaceCardModal] = useState(false);
  const [showOrderCardModal, _showOrderCardModal] = useState(false);
  const [showOrderCardSuccessModal, _showOrderCardSuccessModal] = useState(false);
  const [address, _address] = useState<any>();
  const [addresses, _addresses] = useState<any>([]);
  const [segment, _segment] = useState(props.segment);

  const slidesRef = useRef<any>();

  const [presentAlert] = useIonAlert();

  const history = useHistory();

  const jsConfetti = new JSConfetti();

  useEffect(() => {
    // jsConfetti.addConfetti();

    memberService.getCards().then((res: any) => {
      _cards(res.data);
      for (const card of res.data) {
        if (card.type.issuingType === 'physical') {
          _hasPhysicalCard(true);
          _physicalCard(card);
        } else {
          _virtualCard(card);
        }
      }
    });
    memberService.getMember().then((member: any) => {
      memberService.getAddress(member.data.primaryAddressId).then((res: any) => {
        _address(res.data);
      });
    });
  }, []);

  return (
    <>
      <div style={{ padding: '16px 0px 0px 16px' }}>
        <IonButton
          color={segment == 'card' ? 'dark' : 'light'}
          onClick={() => {
            _segment('card');
            slidesRef.current.slideTo(0);
          }}
        >
          Maxwell Card
        </IonButton>
        <IonButton
          color={segment == 'cash' ? 'dark' : 'light'}
          onClick={() => {
            _segment('cash');
            slidesRef.current.slideTo(2);
          }}
        >
          Regalos
        </IonButton>
      </div>
      <div style={{ paddingLeft: 24, paddingRight: 24 }}>
        <div style={{ paddingTop: 24, fontSize: 14 }}>
          Funds refresh {props.cardholder.fundsRefreshDate}
        </div>
        <div className='card-slides-wrapper'>
          <div className='card-slides-container'>
            <IonSlides
              ref={slidesRef}
              options={{
                initialSlide: props.segment == 'card' ? 0 : 3,
              }}
              onIonSlideDidChange={async (e) => {
                const slideIndex = await e.target.getActiveIndex();
                // console.log("slide changed", slideIndex);
                props.slideChanged(slideIndex);
                if (slideIndex != 2) {
                  _segment('card');
                } else {
                  _segment('cash');
                }
                _currentSlideIndex(slideIndex);
              }}
              pager={true}
              className='card-slides'
            >
              <IonSlide id='virtual' className='card-slide'>
                {virtualCard && (
                  <SlideVirtualCard
                    member={props.member}
                    virtualCard={virtualCard}
                    showCodeModal={(e) => {
                      _showCodeModal(e);
                    }}
                  />
                )}
              </IonSlide>
              <IonSlide className='card-slide'>
                {physicalCard ? (
                  <SlidePhysicalCard
                    member={props.member}
                    physicalCard={physicalCard}
                    cards={cards}
                    hasPhysicalCard={hasPhysicalCard}
                    showReplaceCardModal={(e) => {
                      _showReplaceCardModal(e);
                    }}
                    showOrderCardModal={(e) => {
                      _showOrderCardModal(e);
                    }}
                  />
                ) : (
                  <SlideOrderCard
                    member={props.member}
                    showOrderCardModal={(e) => {
                      _showOrderCardModal(e);
                    }}
                  />
                )}
              </IonSlide>
              <IonSlide className='card-slide'>
                <SlideRegalosCard member={props.member} regalos={props.member.regalosBalance} />
              </IonSlide>
            </IonSlides>
          </div>
        </div>
        <h2> Activity</h2>
        <div style={{ marginLeft: -24, marginRight: -24 }}>
          <CardActivity cardIndex={currentSlideIndex} />
        </div>
        <IonModal
          isOpen={showCodeModal}
          onDidDismiss={() => {
            _code(undefined);
            _showCodeModal(false);
          }}
        >
          <IonContent>
            <div style={{ margin: 24, paddingTop: 40, textAlign: 'center' }}>
              <p style={{ padding: 24 }}>
                <b>Enter 6 digit code from the text message to show card details</b>
              </p>
              {checkingCode === false ? (
                <>
                  <h4 style={{ color: 'red' }}>{codeError}</h4>
                  <MaxwellForm.Input
                    label='Enter Code'
                    value={code}
                    validation={() => null}
                    onChange={(v) => {
                      _code(v);
                    }}
                  />
                  <div style={{ paddingTop: 30 }}>
                    <IonButton
                      color='dark'
                      expand='block'
                      onClick={() => {
                        // get ephemeral key
                        _checkingCode(true);

                        memberService
                          .getEphemeralKey(code, virtualCard.id)
                          .then(async (res: any) => {
                            if (window.Stripe && process.env.REACT_APP_STRIPE_PK) {
                              _code(undefined);
                              _showCodeModal(false);
                              _checkingCode(false);

                              const stripe = await window.Stripe(process.env.REACT_APP_STRIPE_PK);
                              const cardId = res.data.stripeCardId;
                              // Use Stripe.js to create a nonce
                              const nonceResult = await stripe.createEphemeralKeyNonce({
                                issuingCard: cardId,
                              });

                              const elements = stripe.elements();

                              const nonce = nonceResult.nonce;
                              _elKey(res.data.ephemeralKeySecret);

                              stripe
                                .elements()
                                .create('issuingCardNumberDisplay', {
                                  issuingCard: cardId,
                                  nonce: nonce,
                                  ephemeralKeySecret: res.data.ephemeralKeySecret,
                                  style: {
                                    base: {
                                      color: 'white',
                                      fontSize: '16px',
                                    },
                                  },
                                })
                                .mount('#card-number');

                              stripe
                                .elements()
                                .create('issuingCardCvcDisplay', {
                                  issuingCard: cardId,
                                  nonce: nonce,
                                  ephemeralKeySecret: res.data.ephemeralKeySecret,
                                  style: {
                                    base: {
                                      color: 'white',
                                      fontSize: '16px',
                                    },
                                  },
                                })
                                .mount('#card-cvc');

                              stripe
                                .elements()
                                .create('issuingCardExpiryDisplay', {
                                  issuingCard: cardId,
                                  nonce: nonce,
                                  ephemeralKeySecret: res.data.ephemeralKeySecret,
                                  style: {
                                    base: {
                                      color: 'white',
                                      fontSize: '16px',
                                    },
                                  },
                                })
                                .mount('#card-exp');

                              // stripe
                              //   .elements()
                              //   .create("issuingCardCopyButton", {
                              //     toCopy: "number",
                              //   })
                              //   .mount("#card-number-copy");
                            }
                          })
                          .catch((err: any) => {
                            _checkingCode(false);
                            _codeError(err.response.data);
                          });
                      }}
                    >
                      Unlock
                    </IonButton>
                  </div>
                  <div style={{ paddingTop: 20 }}>
                    <IonButton
                      expand='block'
                      color='light'
                      onClick={() => {
                        _code(undefined);
                        _showCodeModal(false);
                      }}
                    >
                      Cancel
                    </IonButton>
                  </div>
                </>
              ) : (
                <div style={{ paddingTop: 30, textAlign: 'center' }}>
                  <h4>Checking Code</h4>
                  <IonSpinner name='crescent' />
                </div>
              )}
            </div>
          </IonContent>
        </IonModal>
        <IonModal isOpen={showReplaceCardModal} onDidDismiss={() => _showReplaceCardModal(false)}>
          <IonHeader style={{ textAlign: 'center' }}>
            <h4>Replace Card?</h4>
          </IonHeader>
          <IonContent>
            <div style={{ margin: 24, paddingTop: 20, textAlign: 'left' }}>
              <h4>Are you sure? </h4>
              <div style={{ padding: 10 }}>A $4 fee may be applied to your account.</div>
              <div style={{ marginBottom: 40 }}>
                <MaxwellForm.VerifyAddress
                  address={address}
                  onChange={(a) => {
                    _address(a);
                  }}
                  value={address}
                  label='Shipping Address'
                  placeholder='Select Shipping Address'
                  addresses={addresses}
                  show={true}
                  setShow={() => null}
                  validation={() => null}
                  editAddress={() => {
                    _showReplaceCardModal(false);
                  }}
                />
              </div>
              <IonButton
                color='dark'
                expand='block'
                disabled={address === undefined}
                onClick={() => {
                  if (address === undefined) return;
                  memberService.orderPhysicalCard(address.id);

                  _showReplaceCardModal(false);
                  // jsConfetti.addConfetti();
                  _showOrderCardSuccessModal(true);
                }}
              >
                Replace Card
              </IonButton>
              <IonButton
                expand='block'
                color='light'
                onClick={() => {
                  _showReplaceCardModal(false);
                }}
              >
                Cancel
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
        <IonModal isOpen={showOrderCardModal} onDidDismiss={() => _showOrderCardModal(false)}>
          <IonHeader style={{ textAlign: 'center' }}>
            <h4>Order Physical Card?</h4>
          </IonHeader>
          <IonContent>
            <div style={{ margin: 24, paddingTop: 20, textAlign: 'left' }}>
              <h4>Are you sure? </h4>
              <p>A $4 fee will be applied to cover production & shipping costs.</p>
              <p>It will be shipped to your home address in 5-10 business days.</p>
              <div style={{ marginBottom: 40 }}>
                <MaxwellForm.VerifyAddress
                  address={address}
                  onChange={(a) => {
                    _address(a);
                  }}
                  value={address}
                  label='Shipping Address'
                  placeholder='Select Shipping Address'
                  addresses={addresses}
                  show={true}
                  setShow={() => null}
                  validation={() => null}
                  editAddress={() => {
                    _showOrderCardModal(false);
                  }}
                />
              </div>
              <IonButton
                color='dark'
                expand='block'
                disabled={address === undefined}
                onClick={() => {
                  if (address === undefined) return;
                  memberService
                    .orderPhysicalCard(address.id)
                    .then((res: any) => {
                      _showOrderCardModal(false);
                      // jsConfetti.addConfetti();
                      _showOrderCardSuccessModal(true);
                    })
                    .catch((err) => {
                      alert(err.response.data);
                    });
                }}
              >
                Order Card
              </IonButton>
              <IonButton
                expand='block'
                color='light'
                onClick={() => {
                  _showOrderCardModal(false);
                }}
              >
                Cancel
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
        <IonModal
          isOpen={showOrderCardSuccessModal}
          onDidDismiss={() => _showOrderCardSuccessModal(false)}
        >
          <IonHeader style={{ textAlign: 'center' }}></IonHeader>
          <IonContent>
            <div style={{ margin: 24, paddingTop: 0, textAlign: 'center' }}>
              <Lottie animationData={successLottie} loop={false} />
              <h4 style={{ marginTop: -80 }}>Success</h4>
              <h4>Your card is on it&apos;s way </h4>
              <div style={{ padding: 10 }}>
                When your card arrives, you will be able to activate it here.
              </div>

              <IonButton
                expand='block'
                color='dark'
                onClick={() => {
                  _showOrderCardSuccessModal(false);
                }}
              >
                Close
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
      </div>
    </>
  );
}
