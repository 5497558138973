import { IonIcon } from '@ionic/react';
import { notificationsOutline } from 'ionicons/icons';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import NotificationService, { notificationsState } from '../Shared/services/NotificationService';
import '../Shared/theme/style.css';

export default function NotificationBell() {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const history = useHistory();

  useEffect(() => {
    NotificationService.getNotifications().then((res: any) => {
      //console.log("getNotifications empty", res.data);
      setNotifications(res.data);
    });
  }, []);

  return (
    <>
      {notifications.filter((x: any) => x.isSeen == false).length > 0 && (
        <>
          <div
            onClick={() => {
              history.push('/notifications');
            }}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              fontSize: 11,
              zIndex: 100,
              fontWeight: 'bold',
              color: 'white',
              textAlign: 'center',
              verticalAlign: 'middle',
              paddingTop: 3,
              backgroundColor: 'red',
              borderRadius: '50%',
              minWidth: 20,
              height: 20,
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.2)',
            }}
          >
            {notifications.filter((x: any) => x.isSeen == false).length}
          </div>
        </>
      )}
      <IonIcon
        onClick={() => {
          history.push('/notifications');
        }}
        style={{ fontSize: 30 }}
        icon={notificationsOutline}
      />
    </>
  );
}
