import { IonIcon } from '@ionic/react';
import { heart, heartOutline } from 'ionicons/icons';

import './theme/style.css';
import { useInView, animated } from '@react-spring/web';
import FavoriteService from './services/FavoriteService';
import ImageService from './services/ImageService';

interface Props {
  width?: any;
  height?: any;
  showFavorite?: boolean;
  favorite?: boolean;
  showAmount?: boolean;
  amount?: number;
  id?: number;
  title?: string;
  light?: boolean;
  isFlex?: boolean;
  picture: string;
  cover?: boolean;
  tag?: string;
  categoryId?: number;
  minWidth?: number;
  maxWidth?: number;
  clicked: () => void;
  favorited?: () => void;
}

export default function Card(props: Props) {
  const [annRef, inView] = useInView(
    () => ({
      from: {
        scale: 1.06,
      },
      to: {
        scale: 1,
      },
    }),
    {
      rootMargin: '0% 0%',
    },
  );

  return (
    <div
      ref={annRef}
      key={props.id}
      className='card'
      style={{
        padding: 0,
        background: props.light ? 'white' : 'rgba(244, 244, 244, 1)',
        position: 'relative',
        width: props.width ? props.width : 156,
        minWidth: props.minWidth ? props.minWidth : 'unset',
        maxWidth: props.maxWidth ? props.maxWidth : 'unset',
        height: props.height ? props.height : 156,
      }}
      onClick={() => {
        props.clicked();
      }}
    >
      <animated.div
        className='card-header-image'
        style={{
          background: !props.light ? 'white' : 'rgba(244, 244, 244, 1)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: props.cover ? 'cover' : 'contain',
          backgroundPosition: 'center center',
          backgroundImage: `url(${ImageService.Url(props.picture)})`,
          ...inView,
        }}
      ></animated.div>
      {props.tag && (
        <div style={{ position: 'absolute', top: 5, left: 5 }}>
          <div className='card-tag'>{props.tag}</div>
        </div>
      )}
      <div className='card-content' style={{ padding: 6, textAlign: 'left', paddingLeft: 10 }}>
        {props.title}
      </div>
      {props.showAmount === true && props.amount && props.amount > 0 && (
        <div
          style={{
            padding: 6,
            position: 'absolute',
            top: 0,
            left: 0,

            backgroundColor: 'white',
            borderRadius: '0 0 8px 0px',
            border: '1px solid black',
            borderBottom: '2px solid black',
            borderTop: 'none',
            borderLeft: 'none',
          }}
        >
          {props.amount && '$' + props.amount.toFixed(2)}
        </div>
      )}

      {props.showFavorite === true && (
        <>
          <div
            style={{
              position: 'absolute',
              padding: 2,
              textAlign: 'right',
              top: 8,
              right: 8,
              fontSize: 24,
              zIndex: 1,
            }}
            onClick={() => {
              if (props.categoryId) {
                FavoriteService.Favorite(props.id, props.categoryId).then(() => {
                  props.favorited && props.favorited();
                });
              }
            }}
          >
            <IonIcon color='primary' icon={props.favorite === true ? heart : heartOutline} />
          </div>
        </>
      )}
    </div>
  );
}
