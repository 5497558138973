import { IonAlert, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { trash } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import AddressService from '../Shared/services/AddressService';
import MemberService, { memberState } from '../Shared/services/MemberService';
import '../Shared/theme/style.css';

interface Props {
  linkable?: boolean;
  avatar?: boolean;
  address?: any;
  isPrimary?: boolean;
  index?: any;
  reload: () => void;
}

export default function AddressItem(props: Props) {
  const [showDelete, _showDelete] = useState(false);
  const [member, _member] = useRecoilState<any>(memberState);
  const history = useHistory();

  return (
    <IonItem key={props.index} lines='full'>
      <IonLabel>
        <IonGrid className='ion-no-padding'>
          <IonRow className='section-header'>
            <IonCol>{props.address.name}</IonCol>
            <IonCol style={{ textAlign: 'right', fontSize: 'small' }}>
              {props.isPrimary ? (
                <IonLabel color='primary'>Primary</IonLabel>
              ) : (
                <IonLabel
                  onClick={() => {
                    AddressService.putAddressPrimary(props.address.id)
                      .then(() => {
                        //TODO
                        // console.log(res);
                        const m = structuredClone(member);
                        m.primaryAddressId = props.address.id;
                        _member(m);
                        props.reload();
                      })
                      .catch((err) => {
                        alert(err.response.data);
                      });
                  }}
                  color='primary'
                >
                  Make Primary
                </IonLabel>
              )}
            </IonCol>
          </IonRow>
          <IonRow className='ion-no-padding'>
            <IonCol className='ion-no-padding'>
              <div className='sub-label'>{props.address.addressCombined}</div>

              <div className='sub-label'>{props.address.cityStateZip}</div>
            </IonCol>
          </IonRow>
          <IonRow style={{ paddingTop: 16 }}>
            <IonCol
              onClick={() => {
                history.push('/address/' + props.address.id, {
                  address: props.address,
                });
              }}
            >
              <IonLabel color='primary'>Edit</IonLabel>
            </IonCol>
            {!props.isPrimary && (
              <IonCol
                onClick={() => {
                  _showDelete(true);
                }}
                style={{ textAlign: 'right' }}
              >
                <IonIcon color='primary' icon={trash} />
              </IonCol>
            )}
            <IonAlert
              isOpen={showDelete}
              onDidDismiss={() => _showDelete(false)}
              header='Watch Out!!! 👀'
              message='Are you sure you want to delete this?'
              buttons={[
                {
                  text: 'Yep',
                  role: 'yep',
                  handler: () => {
                    //TODO
                    // console.log("Yep");
                    MemberService.deleteAddress(props.address.id)
                      .then((res) => {
                        //TODO
                        // console.log(res);

                        props.reload();
                      })
                      .catch((err) => {
                        alert('Address can not be deleted, It may be in use.');
                      });
                  },
                },
                {
                  text: 'Nope',
                  role: 'nope',
                  handler: () => {
                    //TODO
                    // console.log("Nope");
                  },
                },
              ]}
            />
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );
}
