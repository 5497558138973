import { IonButton, IonContent, IonPage, IonTextarea } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import '../Shared/theme/style.css';
import memberService from '../Shared/services/MemberService';

import WizardProgress from './WizardProgress';

interface Props {
  prevStep?: any;
  nextStep?: any;
  handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBAnything(props: Props) {
  const [other, _other] = useState<any>();
  const [member, _member] = useState<any>();

  useEffect(() => {
    memberService.getMember().then((res) => {
      _other(res.data.anything);
      _member(res.data);
    });
  }, []);
  const history = useHistory();
  const Continue = (e: { preventDefault: () => void }) => {
    memberService.putMember({ ...member, other }).then((res) => {
      e.preventDefault();
      props.nextStep();
    });
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className='main-container'>
        <IonContent>
          <div
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                width: '90%',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: 40,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: 'bi-clipboard-heart',
                    complete: true,
                    clicked: () => props.prevStep(10),
                  },
                  {
                    icon: 'bi-people',
                    complete: true,
                    clicked: () => props.prevStep(),
                  },
                  { icon: 'bi-question', complete: true },
                ]}
              />
              <div>
                <h1 style={{ paddingTop: 30 }}>Anything else?</h1>
                <h4>
                  Is there any other important information we should know to serve you better?
                </h4>
              </div>
              <div
                style={{
                  border: '1px solid black',
                  borderRadius: 10,
                  padding: 8,
                  marginTop: 20,
                }}
              >
                <IonTextarea
                  placeholder='Enter other information onboarding'
                  value={other}
                  rows={10}
                  onIonChange={(e) => {
                    _other(e.target.value);
                  }}
                />
              </div>
              <IonButton style={{ marginTop: 30 }} onClick={Continue} expand='block' color='dark'>
                Finish
              </IonButton>
              <IonButton
                style={{ marginTop: 30 }}
                className='backButton'
                expand='block'
                onClick={Back}
                color='light'
              >
                Back
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
