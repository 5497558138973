import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

const AddressService = {
  async getAddresses() {
    return axios.get(`${APIURL}/Addresses/GetAddresses`);
  },
  async getAddress(id: number) {
    return axios.get(`${APIURL}/Addresses/GetAddress/${id}`);
  },
  async putAddress(id: number, address: any) {
    return axios.put(`${APIURL}/Addresses/PutAddress/${id}`, address);
  },
  async postAddress(address: any) {
    return axios.post(`${APIURL}/Addresses/PostAddress`, address);
  },
  async deleteAddress(id: number) {
    return axios.delete(`${APIURL}/Addresses/DeleteAddress/${id}`);
  },
  async putAddressPrimary(id: number) {
    return axios.put(`${APIURL}/Addresses/PutAddressPrimary/${id}`);
  },
  async autoCompleteAddress(query: string) {
    return axios.get(`${APIURL}/Addresses/AutocompleteAddress/${encodeURIComponent(query)}`);
  },
  async getAddressDetails(placeId: string) {
    return axios.get(`${APIURL}/Addresses/GetAddressDetails/${placeId}`);
  },
};

export default AddressService;
