import { IonButton, IonIcon } from '@ionic/react';
import { logoFacebook, logoInstagram, logoLinkedin, logoTwitter } from 'ionicons/icons';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import CONFIG from '../config';
import auth from '../Shared/services/auth';
import { memberState } from '../Shared/services/MemberService';

export default function MenuFooter() {
  const history = useHistory();
  const [member, _member] = useRecoilState<any>(memberState);

  useEffect(() => {
    //console.log("member", member);
  }, []);

  return (
    <>
      <div style={{ paddingLeft: 12 }}>
        <div
          onClick={() => {
            window.open('https://www.instagram.com/maxwell.app.hq/', '_blank');
          }}
          className='social-icon'
        >
          <IonIcon color='primary' icon={logoInstagram} />
        </div>
        <div
          onClick={() => {
            window.open('https://www.facebook.com/maxwell.app.hq', '_blank');
          }}
          className='social-icon'
        >
          <IonIcon color='primary' icon={logoFacebook} />
        </div>
        <div
          onClick={() => {
            window.open('https://www.linkedin.com/company/maxwell-app/', '_blank');
          }}
          className='social-icon'
        >
          <IonIcon color='primary' icon={logoLinkedin} />
        </div>
        <div
          onClick={() => {
            window.open('https://twitter.com/maxwellapphq', '_blank');
          }}
          className='social-icon'
        >
          <IonIcon color='primary' icon={logoTwitter} />
        </div>
      </div>

      <div style={{ padding: '4px 18px 4px 18px ' }}>
        <IonButton
          onClick={() => {
            auth.signOut().then(() => {
              window.location.href = '/login';
            });
          }}
          expand='block'
          color='light'
        >
          Log Out
        </IonButton>

        {/* {isApple() &&
          !window.matchMedia("(display-mode: standalone)").matches && (
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                fontWeight: "bold",
                padding: 20,
              }}
            >
              <IonButton
                onClick={() => {
                  history.push("/install");
                }}
                size="small"
                color="dark"
                expand="block"
              >
                Add To Home Screen
              </IonButton>
            </div>
          )} */}

        <div
          style={{
            color: '#aaa',
            fontSize: 8,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          version: {CONFIG.APPVERSION}
        </div>
      </div>
    </>
  );
}
