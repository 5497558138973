import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRange,
  IonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';

import '../Shared/theme/style.css';
import memberService from '../Shared/services/MemberService';
import onboardingService from '../Shared/services/OnboardingService';

import WizardProgress from './WizardProgress';

interface Props {
  prevStep?: any;
  nextStep?: any;
  handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBEngLevels(props: Props) {
  const [energyScore, _energyScore] = useState<any>(1);
  const [productivityScore, _productivityScore] = useState<any>(1);
  const [selected, _selected] = useState<any>([]);
  const [timeGains, _timeGains] = useState<any>();
  const [timeGainCount, _timeGainCount] = useState<any>();
  const [disable, _disable] = useState<any>(false);

  const [member, _member] = useState<any>();

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);

      for (const s of res.data.memberEnergies) {
        if (s.onboarding === true) {
          _energyScore(s.energyScore);
          _productivityScore(s.productivityScore);
        }
      }

      onboardingService.getTimeGains().then((res2) => {
        _timeGainCount(0);
        _timeGains(res2.data);
        // let newSelected = [];
        // let count = 0;
        // for (let s of res2.data) {
        //   if (res.data.timeGains.filter((e: any) => e.id === s.id).length > 0) {
        //     newSelected.push(true);
        //     count = count + 1;
        //     // console.log(s);
        //   } else {
        //     newSelected.push(false);
        //   }
        // }
        // if (count > 2) {
        //   _disable(true);
        // }
        // _timeGainCount(count);
        // _selected(newSelected);
      });
    });
  }, []);

  function List() {
    return timeGains.map((item: any, index: number) => {
      return (
        <IonItem key={item.name}>
          <IonCheckbox
            title={item.name}
            checked={selected ? selected[index] : false}
            disabled={disable && !selected[index] ? true : false}
            onIonChange={(e) => {
              e.preventDefault();
              e.stopPropagation();

              const newSelected = [...selected];
              newSelected[index] = !newSelected[index];

              const count = newSelected.filter((s) => s).length;

              _disable(count > 2);
              _timeGainCount(count);
              _selected(newSelected);
            }}
            slot='start'
          />
          <IonLabel style={{ paddingLeft: 5 }} text-wrap>
            {item.name}
          </IonLabel>
        </IonItem>
      );
    });
  }

  const Continue = (e: { preventDefault: () => void }) => {
    let index = 0;
    memberService.balanceMember();
    for (const s of selected) {
      onboardingService.TimeGainsUpdateMember({
        memberId: member.id,
        timeGainId: timeGains[index].id,
        addToMember: s,
      });

      index++;
    }

    memberService
      .postEnergy({
        energyScore,
        productivityScore,
        // energyDiminisher,
        MemberEnergyTypeId: 1,
        onboarding: true,
      })
      .then(() => {
        e.preventDefault();
        props.nextStep();
      });
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className='main-container'>
        <IonContent>
          <div
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                width: '90%',
                marginRight: 'auto',
                marginLeft: 'auto',
                paddingTop: 30,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: 'bi-telephone',
                    complete: true,
                    clicked: () => props.prevStep(1),
                  },
                  {
                    icon: 'bi-person',
                    complete: true,
                    clicked: () => props.prevStep(2),
                  },
                  {
                    icon: 'bi-house',
                    complete: true,
                    clicked: () => props.prevStep(3),
                  },
                  {
                    icon: 'bi-camera',
                    complete: true,
                    clicked: () => props.prevStep(4),
                  },
                  { icon: 'bi-lightning', complete: true },
                  { icon: 'bi-list-ul' },
                  { icon: 'bi-person-gear' },
                ]}
              />
              <IonText>
                {/* <h4 className="mw-bold"> */}
                <h1 style={{ paddingTop: 30 }}>How are things going?</h1>
              </IonText>
              <IonLabel class='mw-input-lbl'>What is your current productivity level?</IonLabel>

              <IonRange
                className='mw-nopadding-bottom'
                value={productivityScore}
                onIonChange={(e) => {
                  _productivityScore(e.target.value);
                }}
                ticks={true}
                snaps={true}
                min={1}
                max={10}
                pin={true}
                pinFormatter={(value: number) => `${value}`}
              >
                <IonLabel slot='start'>1</IonLabel>
                <IonLabel slot='end'>10</IonLabel>
              </IonRange>

              <IonLabel class='mw-input-lbl'>What is your current energy level?</IonLabel>

              <IonRange
                className='mw-nopadding-bottom'
                value={energyScore}
                onIonChange={(e) => {
                  _energyScore(e.target.value);
                }}
                ticks={true}
                snaps={true}
                min={1}
                max={10}
                pin={true}
                pinFormatter={(value: number) => `${value}`}
              >
                <IonLabel slot='start'>1</IonLabel>
                <IonLabel slot='end'>10</IonLabel>
              </IonRange>
              <h2 style={{ paddingTop: 30 }}>What will your budget help you do in life?</h2>
              <IonLabel class='mw-input-lbl'>
                These questions help set the intention of how you will use Maxwell and your budget
                it!
              </IonLabel>
              <IonText>
                {/* <h4 className="mw-bold"> */}
                <h4 className=''>{timeGainCount && timeGainCount} of 3 Selected</h4>
              </IonText>
              <IonText>
                {/* <h4 className="mw-bold"> */}
                <h5 className=''>
                  {timeGainCount > 0 ? <>&nbsp;</> : '(Please Select at least one.)'}
                </h5>
              </IonText>
              <div style={{ marginBottom: 40 }}>{timeGains && List()}</div>

              <IonButton
                onClick={Continue}
                disabled={timeGainCount <= 0 ? true : false}
                expand='block'
                color='dark'
              >
                Continue
              </IonButton>
              <IonButton
                style={{ marginTop: 20 }}
                className='backButton'
                expand='block'
                onClick={Back}
                color='light'
              >
                Back
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
