import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';

import '../Shared/theme/style.css';

import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useRecoilState } from 'recoil';

import HeadSubpage from '../Nav/HeaderSubpage';
import memberService, { memberState } from '../Shared/services/MemberService';
import { CardHolder } from '../Shared/types/cardHolder';

import MaxwellCard from './MaxwellCard';

function MaxCard() {
  const [segment, _segment] = useState<any>('card');
  const [member, _member] = useRecoilState(memberState);
  const [cardHolder, _cardHolder] = useState<CardHolder>();
  const history = useHistory();

  // page route params
  const { page } = useParams<any>();

  useIonViewDidEnter(() => {
    if (page == 'regalos') {
      _segment('cash');
    }

    memberService.getMember().then((res) => {
      _member(res.data);
      //console.log(res.data);
      if (res.data && res.data.cardHolders && res.data.cardHolders[0]) {
        _cardHolder(res.data.cardHolders[0]);
      }
    });
  });
  return (
    <IonPage>
      <HeadSubpage returnTo='/home' />
      <IonContent fullscreen className='main-content '>
        <div className='main-container leave-room-for-menu'>
          <div>
            {cardHolder && (
              <MaxwellCard
                member={member}
                cardholder={cardHolder}
                segment={segment}
                slideChanged={(e: number) => {
                  if (e == 2) {
                    _segment('cash');
                    //history.push("/maxcard");
                  }
                  if (e == 1 || e == 0) {
                    _segment('card');
                    //history.push("/maxcard");
                  }
                }}
              />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default MaxCard;
