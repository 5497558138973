import {
  IonApp,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-loading-skeleton/dist/skeleton.css';

/* Theme variables */
import './Shared/theme/variables.css';

import { Auth } from 'aws-amplify';
import axios from 'axios';
import { Redirect, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

// Pages
import PastActivity from './Activity/PastActivity_Page';
import RelatedActivity from './Activity/RelatedActivity_Page';
import Address from './Addresses/Address_Page';
import Addresses from './Addresses/Addresses_Page';
import Announcement from './Announcements/Announcement_Page';
import Announcements from './Announcements/Announcements_Page';
import Login from './Auth/Login_Page';
import SetupPinAuth from './Auth/SetupPinAuth_Page';
import SignUp from './Auth/SignUp_Page';
import Benefit from './Benefits/Benefit_Page';
import Benefits from './Benefits/Benefits_Page';
import Checkout from './Checkout/Checkout_Page';
import CheckoutSuccess from './Checkout/CheckoutSuccess_Page';
import Dependent from './Dependents/Dependent_Page';
import Dependents from './Dependents/Dependents_Page';
import Home from './Home/Home_Page';
import AddFunds from './MaxwellCard/AddFunds_Page';
import AddFundsAmount from './MaxwellCard/AddFundsAmount_Page';
import AddToWallet from './MaxwellCard/AddToWallet_Page';
import MaxCard from './MaxwellCard/MaxCard_Page';
import MemberPage from './Members/Member_Page';
import MembersPage from './Members/Members_Page';
import Menu from './Nav/Menu';
import Notification from './Notifications/Notification_Page';
import Notifications from './Notifications/Notifications_Page';
import Onboarding from './Onboarding/Onboarding_Page';
import Partner from './Partners/Partner_Page';
import PartnerForm from './Partners/PartnerForm_Page';
import Market from './Partners/Partners_Page';
import Reimbursement from './Partners/Reimbursement_Page';
import PetPage from './Pets/Pet_Page';
import Pets from './Pets/Pets_Page';
import ProtectedRoute from './ProtectedRoute';
import Regalos_V2 from './Regalos/Regalos_Page_V2';
import RegalosMessage from './Regalos/RegalosMessage_Page';
import RegalosSend from './Regalos/RegalosSend_Page';
import RegalosTransfer from './Regalos/RegalosTransfer_Page';
import Regalos_Amount_Page from './Regalos/Send/Regalos_Amount_Page';
import Regalos_Send_Page from './Regalos/Send/Regalos_Send_Page';
import Regalos_Sent_Page from './Regalos/Send/Regalos_Sent_Page';
import AddToHome from './Shared/AddToHome_Page';
import Memberships from './User/Memberships_Page';
import UserProfile from './User/UserProfile_Page';

let token: string;

axios.interceptors.request.use(async (req) => {
  token = (await Auth.currentSession()).getIdToken().getJwtToken();
  localStorage.setItem('token', token);

  if (req.headers) {
    req.headers['Content-Type'] = 'application/json';
    req.headers['Authorization'] = 'Bearer ' + token;
    req.headers['Access-Control-Allow-Origin'] = '*';
  }

  return req;
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error === 'No current user') {
      localStorage.removeItem('token');
      return <Redirect to='/login' />;
    }
    return Promise.reject(error);
  },
);

declare global {
  interface Window {
    heap: {
      track: (event: string, properties?: unknown) => void;
      identify: (identity: string) => void;
      resetIdentity: () => void;
      addUserProperties: (properties: unknown) => void;
      addEventProperties: (properties: unknown) => void;
      removeEventProperty: (property: string) => void;
      clearEventProperties: () => void;
      appid: string;
      userId: string;
      identity: string | null;
      config: any;
    };
  }
  const Stripe: any;
}

localStorage.setItem('oldBalance', '0');

setupIonicReact({
  swipeBackEnabled: false,
});

// get employer recoil state
function App() {
  return (
    <RecoilRoot>
      <IonApp style={{ backgroundColor: '#fcfcfc' }}>
        <IonReactRouter>
          <Menu />
          <IonPage id='main'>
            <IonTabs>
              <IonRouterOutlet animated={false}>
                <ProtectedRoute exact path='/home'>
                  <Home />
                </ProtectedRoute>
                <ProtectedRoute exact path='/market'>
                  <Market />
                </ProtectedRoute>
                <ProtectedRoute path='/benefits'>
                  <Benefits />
                </ProtectedRoute>
                <ProtectedRoute path='/regalos'>
                  <Regalos_V2 />
                </ProtectedRoute>
                <ProtectedRoute path='/regalos_v2'>
                  <Regalos_V2 />
                </ProtectedRoute>
                <ProtectedRoute path='/regalos-transfer'>
                  <RegalosTransfer />
                </ProtectedRoute>
                <ProtectedRoute path='/past-activity/'>
                  <PastActivity />
                </ProtectedRoute>
                <ProtectedRoute path='/maxcard/:page?'>
                  <MaxCard />
                </ProtectedRoute>
                <ProtectedRoute path='/benefit/:id?'>
                  <Benefit />
                </ProtectedRoute>
                <ProtectedRoute path='/user'>
                  <UserProfile />
                </ProtectedRoute>
                <ProtectedRoute path='/memberships'>
                  <Memberships />
                </ProtectedRoute>
                <ProtectedRoute path='/addresses'>
                  <Addresses />
                </ProtectedRoute>
                <ProtectedRoute path='/address/:id?'>
                  <Address />
                </ProtectedRoute>
                <ProtectedRoute path='/dependents'>
                  <Dependents />
                </ProtectedRoute>
                <ProtectedRoute path='/dependent/:id?'>
                  <Dependent />
                </ProtectedRoute>
                <ProtectedRoute path='/pets'>
                  <Pets />
                </ProtectedRoute>
                <ProtectedRoute path='/pet/:id?'>
                  <PetPage />
                </ProtectedRoute>

                <ProtectedRoute path='/checkout'>
                  <Checkout />
                </ProtectedRoute>
                <ProtectedRoute path='/checkout-success/'>
                  <CheckoutSuccess />
                </ProtectedRoute>
                <ProtectedRoute path='/addtowallet/'>
                  <AddToWallet />
                </ProtectedRoute>
                <ProtectedRoute path='/addfunds/:amount'>
                  <AddFunds />
                </ProtectedRoute>
                <ProtectedRoute path='/addfundsamount/'>
                  <AddFundsAmount />
                </ProtectedRoute>
                <ProtectedRoute path='/partner/:id?'>
                  <Partner />
                </ProtectedRoute>
                <ProtectedRoute exact path='/'>
                  <Redirect to='/home' />
                </ProtectedRoute>
              </IonRouterOutlet>
              <IonTabBar className='mobile-tabs' slot='bottom'>
                <IonTabButton tab='home' href='/home'>
                  <i className='icon bi-house'></i>
                  <IonLabel>Home</IonLabel>
                </IonTabButton>

                <IonTabButton
                  className={
                    'MarketplaceTab ' +
                    (localStorage.getItem('hideMarketplaceTab') == 'true' && 'hide')
                  }
                  tab='market'
                  href='/market'
                >
                  <i className='icon bi-shop-window'></i>
                  <IonLabel>Marketplace</IonLabel>
                </IonTabButton>

                <IonTabButton
                  className={
                    'BenefitsTab ' + (localStorage.getItem('hideBenefitsTab') == 'true' && 'hide')
                  }
                  tab='benefits'
                  href='/benefits'
                >
                  <i className='icon bi-flower3'></i>
                  <IonLabel>Benefits+</IonLabel>
                </IonTabButton>
                <IonTabButton
                  className={
                    'RegalosTab ' + (localStorage.getItem('hideRegalosTab') == 'true' && 'hide')
                  }
                  tab='regalos'
                  href='/regalos'
                >
                  <i className='icon bi-gift'></i>
                  <IonLabel>Regalos</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>

            <ProtectedRoute path='/regalos_send'>
              <Regalos_Send_Page />
            </ProtectedRoute>
            <ProtectedRoute path='/regalos_send_amount/:id'>
              <Regalos_Amount_Page />
            </ProtectedRoute>

            <ProtectedRoute path='/regalos_sent/:id'>
              <Regalos_Sent_Page />
            </ProtectedRoute>

            <ProtectedRoute path='/members/'>
              <MembersPage />
            </ProtectedRoute>
            <ProtectedRoute path='/member/:id?'>
              <MemberPage />
            </ProtectedRoute>

            <ProtectedRoute path='/regalos-message/:id?'>
              <RegalosMessage />
            </ProtectedRoute>

            <ProtectedRoute path='/regalos-send/:id'>
              <RegalosSend />
            </ProtectedRoute>
            <ProtectedRoute path='/announcement/:id?'>
              <Announcement />
            </ProtectedRoute>
            <ProtectedRoute path='/notification/:id/'>
              <Notification />
            </ProtectedRoute>
            <ProtectedRoute path='/related-activity/:id'>
              <RelatedActivity />
            </ProtectedRoute>

            <ProtectedRoute path='/partner_item_form/:id?'>
              <PartnerForm />
            </ProtectedRoute>

            <ProtectedRoute path='/announcements'>
              <Announcements />
            </ProtectedRoute>
            <ProtectedRoute path='/notifications'>
              <Notifications />
            </ProtectedRoute>

            <ProtectedRoute path='/reimbursement/:id/:type'>
              <Reimbursement />
            </ProtectedRoute>

            <ProtectedRoute path='/setup-pin/'>
              <SetupPinAuth />
            </ProtectedRoute>
            <ProtectedRoute path='/onboarding/:page?'>
              <Onboarding />
            </ProtectedRoute>
            <Route component={Login} path='/login' />
            <Route component={SignUp} path='/signup' />
            <Route component={AddToHome} path='/install' />
          </IonPage>
        </IonReactRouter>
      </IonApp>
    </RecoilRoot>
  );
}

export default App;
