import { useHistory } from 'react-router';

import '../Shared/theme/style.css';
import { GetActivityId } from '../Shared/services/ActivityService';
import { Activity } from '../Shared/types/activity';

import ActivityAvatar from './ActivityAvatar';

interface Props {
  activity: Activity;
  clicked: () => void;
}

export default function ActivityDetails(props: Props) {
  const history = useHistory();

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          paddingTop: 24,
          paddingLeft: 'calc(50% - 60px)',
        }}
      >
        <ActivityAvatar size={120} activity={props.activity} />
      </div>
      <div style={{ padding: '0 24px 24px 24px', textAlign: 'center' }}>
        <h3>{props.activity && props.activity.title && props.activity.title}</h3>
        <div style={{ paddingBottom: 10, fontSize: 'small', marginTop: -10 }}>
          {props.activity && props.activity.activityType && props.activity.activityType.name}
        </div>
        <div style={{ fontSize: 'small' }}>ID: {GetActivityId(props.activity)}</div>
        <div style={{ fontSize: 'small' }}>
          {props.activity && props.activity.date && (
            <>
              {new Date(props.activity.date).toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
              <br />
              {new Date(props.activity.date).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
              {props.activity.merchant && (
                <>
                  <br />
                  {props.activity.merchant?.name}
                  <br />
                  {props.activity.merchant?.city}, {props.activity.merchant?.state}
                </>
              )}
            </>
          )}
        </div>
        <div>
          {props.activity && props.activity.amount && (
            <div style={{ fontSize: 32, fontWeight: 'bold', paddingTop: 5 }}>
              {(props.activity.amount / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </div>
          )}
        </div>
        <div style={{ color: 'red', fontWeight: 'bold' }}>
          {props.activity &&
            props.activity.activityStatus &&
            props.activity.activityStatus.complete != true &&
            props.activity.activityStatus.name}
        </div>
      </div>
    </>
  );
}
