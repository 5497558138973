import _ from 'lodash';
import { useEffect, useState } from 'react';

import partnerService from '../Shared/services/PartnerService';
import '../Shared/theme/style.css';

import PartnerFeaturedItem from './PartnerFeaturedItem';
import PartnerFeaturedItemPopUp from './PartnerFeaturedItemPopUp';

export default function PartnerFeaturedItems() {
  const [featured, setFeatured] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>();

  useEffect(() => {
    partnerService.featuredItems().then((res) => {
      // console.log("featured items", res.data);
      setFeatured(res.data);
    });
  }, []);

  function FeaturedList(items: any) {
    if (items !== null && items.length > 0) {
      return _.orderBy(items, 'sortWeight').map((item: any) => {
        return (
          <PartnerFeaturedItem
            key={item.id}
            item={item}
            selected={(i) => {
              // console.log("selected", i);
              setSelectedItem(i);
            }}
          />
        );
      });
    }
  }
  return (
    <div className=''>
      <div style={{ paddingLeft: 20, fontWeight: 500 }}>Products we love…</div>
      <div>
        <div style={{ width: '100vw' }}>
          {featured.length != 0 && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                overflowX: 'scroll',
                overflowY: 'hidden',
                flexFlow: 'row',
                width: '100%',
                maxWidth: 896,
                height: '100%',
                padding: 10,
                paddingBottom: 28,
              }}
            >
              <>{FeaturedList(featured)} </>
            </div>
          )}
        </div>
      </div>
      {selectedItem && (
        <PartnerFeaturedItemPopUp
          item={selectedItem}
          closed={() => {
            setSelectedItem(undefined);
          }}
        />
      )}
    </div>
  );
}
