import {
  IonAvatar,
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
} from '@ionic/react';
import { heart, heartOutline, paperPlaneOutline } from 'ionicons/icons';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useRecoilState } from 'recoil';

import HeadSubpage from '../Nav/HeaderSubpage';
import { MxImg } from '../Shared/services/ImageService';
import { memberState } from '../Shared/services/MemberService';
import RegalosService from '../Shared/services/RegalosService';
import '../Shared/theme/style.css';
import { Member } from '../Shared/types/member';
import { Regalos } from '../Shared/types/regalos';
import { RegalosMessage as RegalosMessageType } from '../Shared/types/regalosMessage';

export default function RegalosMessage() {
  const [commentText, _commentText] = useState<any>();
  const history = useHistory();
  const [activity, _activity] = useState<Regalos>();

  const [member, _member] = useRecoilState<any>(memberState);
  const [messages, _messages] = useState();
  const [count, _count] = useState(0);
  const [hearted, _hearted] = useState(false);

  const [isPublic, _isPublic] = useState<any>(false);

  const { id } = useParams<any>();

  useEffect(() => {
    RegalosService.getRegalos(id).then((res) => {
      _activity(res.data);
      _isPublic(res.data.isPublic);
      _messages(res.data.regalosMessages);
      if (res.data.heartedMembers) {
        for (const m of res.data.heartedMembers) {
          if (m.id == member.id) {
            _hearted(true);
          }
        }
      }
    });
  }, []);

  function RegMsgList(msgs: any) {
    if (msgs !== null) {
      const msgdata = _.orderBy(msgs, ['createdDate', 'dsc']);
      return msgdata
        .filter((x) => x.message)
        .map((msg: RegalosMessageType, index: number) => {
          return (
            <IonItem key={index} lines='none'>
              <IonAvatar
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  history.push('/member/' + msg.memberId);
                }}
                slot={msg.memberId == member.id ? 'end' : 'start'}
              >
                {msg.member && msg.member.picture && (
                  <MxImg alt='Silhouette of mountains' src={msg.member.picture} />
                )}
              </IonAvatar>

              <IonGrid
                style={{
                  textAlign: msg.memberId == member.id ? 'right' : 'left',
                }}
                className='ion-no-padding'
              >
                <IonRow>
                  <IonCol>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        marginTop: 3,
                      }}
                    >
                      {msg.member && msg.member.name}
                    </div>

                    <div
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {msg.datemmddyyyy}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div
                      style={{
                        border: '2px solid black',
                        borderRadius: 10,
                        padding: 8,
                        display: 'inline-block',
                        margin: 6,
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.2)',
                        maxWidth: 'calc(100% - 100px)',
                      }}
                    >
                      {msg.message}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          );
        });
    }
  }

  function Hearted(members: Array<Member>) {
    return members.map((member: any, index: number) => {
      return (
        <IonChip
          style={{ margin: 2, maxWidth: 110 }}
          onClick={() => {
            history.push('/member/' + member.id);
          }}
          key={index}
        >
          <IonAvatar className='favorite' style={{ borderRadius: 25, width: 30, height: 25 }}>
            <MxImg alt={member.name} src={member.picture} />
          </IonAvatar>
          <IonLabel style={{ fontSize: 12 }}>{member.name}</IonLabel>
        </IonChip>
      );
    });
  }

  function isMine() {
    return (
      activity &&
      (activity.senderMember?.id === member?.id || activity.receiverMember?.id === member?.id)
    );
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        {activity && (
          <div className='main-container leave-room-for-menu' style={{ position: 'relative' }}>
            {isPublic ? (
              <>
                {activity.senderMemberId == member.id || activity.receiverMemberId == member.id ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 25,
                      right: 10,
                      zIndex: 99,
                    }}
                  >
                    <IonButton
                      onClick={() => {
                        const regalos = structuredClone(activity);
                        regalos.isPublic = false;
                        _isPublic(false);
                        void RegalosService.UpdateRegalos(activity.id, regalos);
                      }}
                      color='dark'
                      size='small'
                    >
                      Make Private
                    </IonButton>
                  </div>
                ) : null}
              </>
            ) : (
              <div
                style={{
                  position: 'absolute',
                  top: 25,
                  right: 16,
                  zIndex: 99,
                  fontWeight: 'bold',
                }}
              >
                <i style={{ fontSize: 16 }} className='fas fa-eye-slash'></i> Private
              </div>
            )}
            <div>
              <IonItem lines='none'>
                <IonAvatar
                  style={{ marginBottom: 14 }}
                  onClick={() => {
                    history.push('/member/' + activity.senderMember?.id);
                  }}
                  slot='start'
                >
                  {activity && activity.senderMember && activity.senderMember?.picture && (
                    <MxImg alt='Silhouette of mountains' src={activity.senderMember?.picture} />
                  )}
                </IonAvatar>

                <IonLabel>
                  <div className='section-header'>
                    {activity.senderMember?.name}
                    <br />
                    <span style={{ fontWeight: 'normal' }}>to</span> {activity.receiverMember?.name}
                  </div>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className='ion-no-padding'>
                      <IonCol className='ion-no-padding'>
                        <div className='sub-label'>{activity.datemmddyyyy}</div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonLabel>
              </IonItem>
              <div style={{ paddingLeft: 22, paddingRight: 22 }}>
                <IonGrid className='ion-no-padding'>
                  <IonRow>
                    <IonCol style={{ marginTop: 5, padding: 5 }}>{activity.note}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{
                        textAlign: 'right',
                        fontWeight: 500,
                        paddingRight: 16,
                      }}
                    >
                      {isMine() && <>${(parseFloat(activity?.amount) / 100).toFixed(2)}</>}
                    </IonCol>
                  </IonRow>
                  <IonIcon
                    icon={hearted ? heart : heartOutline}
                    style={{ fontSize: 24, marginBottom: -3 }}
                    onClick={() => {
                      if (!hearted) {
                        RegalosService.putRegalosHeart(activity.id);
                        const newActivity = structuredClone(activity);
                        newActivity.heartedMembers.push(member);
                        _activity(newActivity);
                      } else {
                        RegalosService.putRegalosUnheart(activity.id).then(() => {
                          RegalosService.getRegalos(id).then((res) => {
                            _activity(res.data);
                          });
                        });
                      }
                      _hearted(!hearted);
                    }}
                  />{' '}
                  Likes
                  <div className='lines' style={{ borderBottom: '0px solid black' }}></div>
                  <IonRow style={{ marginLeft: -10, paddingTop: 0 }}>
                    <IonCol style={{ minHeight: 45 }}>
                      {activity && activity.heartedMembers && Hearted(activity.heartedMembers)}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <div>
              <IonList style={{ backgroundColor: '#fcfcfc !important' }}>
                <div
                  style={{
                    fontWeight: 500,
                    marginLeft: 22,
                    fontSize: 18,
                    backgroundColor: '#fcfcfc !important',
                  }}
                >
                  Comments
                </div>

                <div style={{ height: 6, width: '100%' }}></div>
                {messages && RegMsgList(messages)}
                <div style={{ width: '100%', height: 100 }}></div>
              </IonList>
            </div>
            <div className='chatBox'>
              <div
                style={{
                  display: 'inline-block',
                  width: 'calc(100% - 60px)',
                  paddingLeft: 22,
                  backgroundColor: 'white !important',
                }}
              >
                <textarea
                  style={{
                    width: '100%',
                    height: 40,
                    border: '0px solid #ccc',
                    padding: 10,
                    marginTop: 4,
                    fontSize: 14,
                    backgroundColor: 'white !important',
                    color: 'black',
                  }}
                  value={commentText}
                  placeholder='Enter Comment'
                  onChange={(e) => _commentText(e.currentTarget.value)}
                />
              </div>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  padding: 12,
                  fontSize: 26,
                }}
              >
                <IonIcon
                  onClick={() => {
                    const message: any = {
                      regalosId: id,
                      message: commentText,
                    };
                    const newMessages: any = structuredClone(messages);
                    if (newMessages && newMessages.length > 0) {
                      newMessages.push(message);
                      _messages(newMessages);
                    }

                    RegalosService.postRegalosMessage(message).then(() => {
                      _commentText('');
                      _count(count + 1);
                      RegalosService.getRegalos(id).then((res) => {
                        _activity(res.data);
                        //console.log(res.data);
                        _messages(res.data.regalosMessages);
                        _commentText('');
                      });
                    });
                  }}
                  slot='end'
                  mode='md'
                  icon={paperPlaneOutline}
                />
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
