import { Auth } from 'aws-amplify';
import axios from 'axios';
import { atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

const APIURL = process.env.REACT_APP_API_HOST;

export const authUserState = atom({
  key: 'authUserState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

Auth.configure({
  Auth: {
    production: process.env.REACT_APP_AUTH_PRODUCTION,
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  authenticationFlowType: process.env.REACT_APP_AUTH_FLOW_TYPE,
});

const auth = {
  async signIn({ email, phoneNumber }: { email?: string; phoneNumber?: string }) {
    const username = email || phoneNumber || '';

    const queryParams = new URLSearchParams();
    if (email) {
      queryParams.append('email', email);
    }
    if (phoneNumber) {
      queryParams.append('phoneNumber', phoneNumber);
    }

    const res = await fetch(`${APIURL}/Auth/InitialAuth?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.statusText !== 'OK') {
      if (res.statusText === 'Not Found' && !username.includes('+')) {
        alert('User not setup. Please check your username and try again or contact support.');
      }
    }

    // we need to keep this Auth.signIn(username) call to keep the data model that amplify provides
    return Auth.signIn(username);
  },
  async signOut() {
    return Auth.signOut();
  },
  async getSignUpCreds(email: string) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 12;
    let password = '';
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    // Auth Attributes
    const username = uuidv4();
    return {
      username,
      password,
      email,
    };
  },
  async signUp(signUpCreds: any) {
    Auth.signUp({
      username: signUpCreds.username,
      password: signUpCreds.password,
      attributes: {
        email: signUpCreds.email,
        name: signUpCreds.email,
      },
    });
  },
  async checkPin(pin: any) {
    return axios.post(`${APIURL}/Auth/CheckPin`, { pin });
  },
  async setPin(pin: any, verificationCode: string) {
    return axios.post(`${APIURL}/Members/StorePinNumber`, {
      PinNumber: pin,
      ValidationCode: verificationCode,
    });
  },
  async setPinFirstTime(pin: any) {
    return axios.post(`${APIURL}/Members/FirstTimePinNumber`, {
      PinNumber: pin,
    });
  },
  async setPhone(phone: string) {
    this.getAuthUser().then((user) => {
      Auth.updateUserAttributes(user, {
        phone_number: phone,
        phone_number_verified: 'true',
      }).then(() => {
        axios.get(`${APIURL}/Auth/SetCognitoPhoneVerified`);
      });
    });
  },
  async authCode(user: any, code: string) {
    return Auth.sendCustomChallengeAnswer(user, code).then((userdata) => {
      return userdata;
    });
  },
  async getAuthUser() {
    return await Auth.currentAuthenticatedUser();
  },
  async credentials() {
    return await Auth.currentSession();
  },

  async postFinishAuth(accessToken: string, idToken: string) {
    const data = { jwtToken: idToken };

    return axios.post(`${APIURL}/Auth/FinishCognitoAuth`, data, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },
};

export default auth;
