import _ from 'lodash';

export default function TheBigNumber(numProps: { total: any; title: string }) {
  let total = numProps.total;
  if (numProps.total < 0) {
    total = 0;
  }

  return (
    <>
      <div
        className='big-number-font'
        style={{
          display: 'inline-block',
          fontSize: 65,
          fontWeight: 100,
          verticalAlign: 'top',
          paddingTop: 10,
          paddingRight: 2,
        }}
      >
        $
      </div>
      <div
        className='big-number-font'
        style={{
          display: 'inline-block',
          fontSize: 80,
          fontWeight: 300,
          verticalAlign: 'top',
        }}
      >
        {_.split((total / 100).toString(), '.')[0]}
      </div>

      <div
        style={{
          display: 'inline-block',
          fontSize: 50,
          verticalAlign: 'top',
          paddingTop: 14,
        }}
      >
        <div
          className='big-number-font'
          style={{
            fontSize: 40,
            fontWeight: 400,
          }}
        >
          .
          {total != 0
            ? total < 10
              ? '0' + Math.round(total).toString().slice(-2)
              : Math.round(total).toString().slice(-2)
            : '00'}
        </div>
        <div
          style={{
            textAlign: 'left',
            fontSize: 12,
            paddingLeft: 2,
            marginTop: -4,
            verticalAlign: 'top',
            letterSpacing: 1,
            textTransform: 'uppercase',
            marginBottom: 40,
          }}
        >
          {numProps.title}
        </div>
      </div>
      {/* {numProps.total < 0 && (
          <div
            style={{
              border: "2px solid burlywood",
              borderRadius: 10,
              fontSize: 10,
              padding: 4,
              color: "black",
              fontWeight: 500,
              width: "calc(100% - 48px)",
              position: "absolute",
              bottom: 10,
              left: 0,
              marginLeft: 24,
              display: "block",
              backgroundColor: "rgba(222,184,135,.8)",
            }}
          >
            Oops, some how you overspent by ${numProps.total / -100}. Don't
            worry, we'll simply adust your next top up accordingly.{" "}
          </div>
        )} */}
    </>
  );
}
