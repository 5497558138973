import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  useIonAlert,
} from '@ionic/react';

import '../Shared/theme/style.css';

import { loadStripe } from '@stripe/stripe-js';
import { alertCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import HeadSubpage from '../Nav/HeaderSubpage';
import MaxwellForm from '../Shared/services/MaxwellForm';

export default function AddFundsAmount() {
  const history = useHistory();
  const [stripePromise, _stripePromise] = useState<any>(null);
  const [amount, _amount] = useState<any>(null);
  const [fee, _fee] = useState<any>(null);
  const [total, _total] = useState<any>(null);

  useEffect(() => {
    const pk = process.env.REACT_APP_STRIPE_PK;
    if (pk) {
      _stripePromise(loadStripe(pk));
    }
  }, []);

  useEffect(() => {
    // OrderService.postPaymentIntent(100).then((res) => {
    //   // console.log(res.data);
    //   _clientSecret(res.data);
    // });
  }, []);

  const [presentAlert] = useIonAlert();

  const options = [
    { id: 0, name: '$0' },
    { id: 5, name: '$5' },
    { id: 10, name: '$10' },
    { id: 20, name: '$20' },
    { id: 50, name: '$50' },
    { id: 100, name: '$100' },
    { id: 250, name: '$250' },
  ];

  function maxwellCurrency(number: string) {
    return (
      <div style={{ fontWeight: 600 }}>
        ${parseFloat(number).toFixed(2).split('.')[0]}
        <sup style={{ textDecoration: 'underline' }}>
          {parseFloat(number).toFixed(2).split('.')[1]}
        </sup>
      </div>
    );
  }

  return (
    <IonPage>
      <IonContent fullscreen className='main-content'>
        <div className='main-container'>
          <HeadSubpage />
          <div style={{ padding: 24, textAlign: 'left', marginTop: 50 }}>
            <h2>Add Funds to Your Maxwell Account</h2>

            <MaxwellForm.Select
              label='Amount'
              options={options}
              value={amount}
              required={true}
              validation={() => null}
              onChange={(value: number) => {
                value = parseFloat(value.toString());
                const stripeValue = value * 1.029 + 0.3;
                const calcFee = stripeValue * 0.029 + 0.3;
                _amount(value);
                _fee(calcFee);
                // console.log("total:", calcFee, value);
                _total(calcFee + value);
              }}
            />
            {amount && fee && total && (
              <IonGrid>
                <IonRow>
                  <IonCol offsetSm='4' style={{ textAlign: 'right' }}>
                    <IonLabel>Amount</IonLabel>
                  </IonCol>
                  <IonCol sizeSm='2' style={{ textAlign: 'right' }}>
                    <IonLabel>{maxwellCurrency(amount)}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol offsetSm='4' style={{ textAlign: 'right' }}>
                    <IonLabel>
                      <IonIcon
                        onClick={() => {
                          presentAlert({
                            header: 'More Info',
                            message: 'This is the payment processing fee charged by the network.',
                            buttons: ['OK'],
                          });
                        }}
                        icon={alertCircleOutline}
                      />{' '}
                      Service Fee
                    </IonLabel>
                  </IonCol>
                  <IonCol sizeSm='2' style={{ textAlign: 'right' }}>
                    <IonLabel>{maxwellCurrency(fee)}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol offsetSm='4' style={{ textAlign: 'right' }}>
                    <IonLabel>Total</IonLabel>
                  </IonCol>
                  <IonCol sizeSm='2' style={{ textAlign: 'right' }}>
                    <IonLabel>{maxwellCurrency(total)}</IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}

            <IonButton
              disabled={!amount}
              expand='block'
              color='dark'
              onClick={() => {
                history.push('/addfunds/' + amount * 100);
              }}
            >
              Continue
            </IonButton>

            <div style={{ marginTop: 40 }}>
              <IonButton expand='block' color='light' onClick={() => history.goBack()}>
                Cancel
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
