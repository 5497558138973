import { IonContent, IonPage } from '@ionic/react';

import '../Shared/theme/style.css';
import HeadSubpage from '../Nav/HeaderSubpage';

export default function AddToWallet() {
  return (
    <IonPage>
      <IonContent fullscreen className='main-content'>
        <div className='main-container'>
          <HeadSubpage />
          <div style={{ padding: 24, textAlign: 'left', marginTop: 50 }}>
            <h2>Adding Your Card to Your Wallet</h2>
            <div style={{ paddingTop: 10 }}>
              <p>
                <b>Step 1</b> <br />
                Unlock card to obtain card details
              </p>
              <p>
                <b>Step 2</b> <br />
                Launch the Wallet app on your iPhone or Android device.
              </p>
              <p>
                <b>Step 3</b>
                <br />
                Tap the plus icon in the upper right corner.
              </p>
              <p>
                <b>Step 4</b>
                <br />
                Tap &ldquo;Add Credit or Debit Card&rdquo;.
              </p>
              <p>
                <b>Step 5</b>
                <br />
                Enter your card information manually.
              </p>
              <p>
                <b>Step 6</b>
                <br />
                Tap &ldquo;Done&rdquo; and verify your card details.
              </p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
