import { IonButton } from '@ionic/react';
import { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { useRecoilState } from 'recoil';

import '../Shared/theme/style.css';
import auth from '../Shared/services/auth';
import memberService, { memberState } from '../Shared/services/MemberService';

interface Props {
  authed: () => void;
}

export default function PinAuth(props: Props) {
  const [code, _code] = useState('');
  const [member, _member] = useRecoilState<any>(memberState);
  const [resetPin, _resetPin] = useState(false);
  const [verificationCode, _verificationCode] = useState('');
  const [validEmailCode, _validEmailCode] = useState(false);
  const [showInput, _showInput] = useState(false);
  const [showAuthCode, _showAuthCode] = useState(true);

  function validateCode(pin: any) {
    console.log(pin);
    // do something
    auth
      .checkPin(pin)
      .then((res) => {
        if (res.data) {
          localStorage.setItem('pin', pin);
          props.authed();
        } else {
          // do something
          alert('failed');
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Invalid Pin');
        _showAuthCode(false);
        setTimeout(() => {
          _showAuthCode(true);
        }, 1000);

        _code('');
      });
  }

  function setCode(pin: any, verificationCode: string) {
    // do something
    auth.setPin(pin, verificationCode).then((res) => {
      if (res.data) {
        const m = member;
        m.accountPinSet = true;
        _member(m);
        localStorage.setItem('pin', pin);
        props.authed();
      } else {
        // do something
        alert('failed');
      }
    });
  }

  function validateEmailCode(code: any) {
    // do something
    memberService
      .validateSmsCode(code)
      .then((res: any) => {
        if (res.data) {
          // do something
          _validEmailCode(true);
        }
      })
      .catch((err) => {
        alert(err);
        _verificationCode('');
      });
  }

  function AuthPin() {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <div style={{ fontWeight: 'bold' }}>Enter Your PIN</div>
        <div
          style={{
            maxWidth: 400,
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 'calc(50% - 200px)',
          }}
        >
          We need to verify your identity. Please enter your account PIN.
        </div>
        {showAuthCode && (
          <AuthCode
            inputClassName='mw-code-input'
            onChange={(v) => {
              _code(v);
              if (v.length == 4) {
                // do something
                validateCode(v);
              }
            }}
            allowedCharacters='numeric'
            autoFocus={true}
            length={4}
          />
        )}
        <div style={{ paddingTop: 30 }}>
          <IonButton
            color='dark'
            size='small'
            onClick={() => {
              _resetPin(true);
            }}
          >
            forgot PIN
          </IonButton>
        </div>
      </div>
    );
  }

  function SetPin() {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontWeight: 'bold' }}>Set Your PIN</div>
        <p
          style={{
            maxWidth: 400,
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 'calc(50% - 200px)',
          }}
        >
          From time to time, we may need to verify your identity. Please set an account PIN to make
          this easier.
        </p>
        <AuthCode
          inputClassName='mw-code-input'
          onChange={(v) => {
            _code(v);
          }}
          allowedCharacters='numeric'
          autoFocus={true}
          length={4}
        />
        <div style={{ paddingTop: 30 }}>
          <IonButton
            color='dark'
            size='small'
            onClick={() => {
              // do something
              setCode(code, verificationCode);
              setTimeout(() => {
                props.authed();
              }, 1000);
            }}
          >
            set account PIN
          </IonButton>
        </div>
      </div>
    );
  }

  function GetVerificationCode() {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontWeight: 'bold', paddingTop: 40 }}>Validate Email Ownership</div>
        <p
          style={{
            maxWidth: 400,
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 'calc(50% - 200px)',
          }}
        >
          Before we can set your PIN, we need to verify your identity. Please enter the verification
          code sent to your email address.
        </p>
        {!showInput ? (
          <IonButton
            color='dark'
            onClick={() => {
              _showInput(true);
              void memberService.sendEmailCode();
            }}
          >
            Send Code To Email
          </IonButton>
        ) : (
          <>
            <AuthCode
              inputClassName='mw-code-input'
              onChange={(v) => {
                if (v.length == 6) {
                  _verificationCode(v);
                  validateEmailCode(v);
                }
              }}
              allowedCharacters='numeric'
              autoFocus={true}
              length={6}
            />
            <div style={{ paddingTop: 30 }}>
              <IonButton
                color='dark'
                size='small'
                onClick={() => {
                  _showInput(false);
                }}
              >
                retry
              </IonButton>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      {member && !member.AccountPinSet && !resetPin ? (
        <>{AuthPin()}</>
      ) : (
        <>{validEmailCode ? SetPin() : GetVerificationCode()}</>
      )}
    </div>
  );
}
