import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

const onboardingService = {
  async get() {
    return axios.get(`${APIURL}/Onboarding/GetOnboarding`);
  },
  async post(model: any) {
    return axios.post(`${APIURL}/Onboarding/PostOnboarding`, model, {
      headers: {
        // 'application/json' is the modern content-type for JSON, but some
        // older servers may use 'text/json'.
        // See: http://bit.ly/text-json
        'content-type': 'application/json',
      },
    });
  },
  async getCareGivers() {
    return axios.get(`${APIURL}/CareGivers/GetCareGivers`);
  },
  async getExistingBenefits() {
    return axios.get(`${APIURL}/ExistingBenefits/GetExistingBenefits`);
  },
  //UpdateMember
  async ExistingBenefitsUpdateMember(body: any) {
    return axios.put(`${APIURL}/ExistingBenefits/UpdateMember`, body);
  },
  async RacesUpdateMember(body: any) {
    return axios.put(`${APIURL}/Races/UpdateMember`, body);
  },
  async RacesSetMember(body: any) {
    return axios.put(`${APIURL}/Races/SetMember`, body);
  },
  async TimeGainsUpdateMember(body: any) {
    return axios.put(`${APIURL}/TimeGains/UpdateMember`, body);
  },
  async getGenders() {
    return axios.get(`${APIURL}/Genders/GetGenders`);
  },
  async getLocations() {
    return axios.get(`${APIURL}/Locations/GetLocations`);
  },
  async getMaritals() {
    return axios.get(`${APIURL}/Maritals/GetMaritals`);
  },
  async getRaces() {
    return axios.get(`${APIURL}/Races/GetRaces`);
  },
  async getRace(id: number) {
    return axios.get(`${APIURL}/Races/GetRace/${id}`);
  },
  async getTimeGains() {
    return axios.get(`${APIURL}/TimeGains/GetTimeGains`);
  },
  async getTimeGain(id: number) {
    return axios.get(`${APIURL}/TimeGains/GetTimeGain/${id}`);
  },
  // async getRelationships() {
  //   return axios.get(`${APIURL}/Relationships/GetRelationships`);
  // },
};

export default onboardingService;
