import { IonButton, IonContent, IonImg, IonPage, IonText } from '@ionic/react';
import { useHistory } from 'react-router';

import '../Shared/theme/style.css';

export default function OBSuccess() {
  const history = useHistory();
  const goHome = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    history.push('/home');
  };

  return (
    <IonPage>
      <div className='main-container'>
        <IonContent fullscreen>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '90%',
                maxWidth: 400,
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: 40,
                paddingTop: 30,
              }}
            >
              <IonImg src={'./assets/partyhat.png'} className='SuccessImg' />
              <IonText>
                <h1>You are done!</h1>
                <div
                  style={{
                    marginBottom: 50,
                    marginTop: 30,
                    fontSize: 20,
                  }}
                >
                  &ldquo;We cannot wait to make your life easier and for
                  <b>
                    {' '}
                    you to do what you love. <br /> It&apos;s an honor & a pleasure&rdquo;
                  </b>
                  <div style={{ textAlign: 'right', paddingRight: 50 }}>--Max.</div>
                </div>
              </IonText>
              <IonButton
                onClick={goHome}
                expand='block'
                color='dark'
                class='ion-color ion-color-dark ios button button-block button-solid ion-activatable ion-focusable'
              >
                Start Using Maxwell
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
